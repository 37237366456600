import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Form, Input, Button, Select, DatePicker, message, 
  Spin, Typography, Row, Col, Card, Space, Upload, 
  Divider, Alert, Progress
} from 'antd';
import { ArrowLeftOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { useTeachers } from '../services/useTeachers';
import { TeacherBaseInfo, TeacherUser } from '../types';
import { fileAPI } from '../services/api';
import './TeacherBasicInfo.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const TeacherBasicInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const teacherId = Number(id);
  const [form] = Form.useForm();
  
  const { 
    selectedTeacher, 
    loading, 
    error, 
    fetchTeacherDetails,
    updateTeacherBaseInfo
  } = useTeachers();
  
  const [saving, setSaving] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  
  // 页面加载时获取教师信息
  useEffect(() => {
    if (teacherId) {
      console.log('TeacherBasicInfo: 加载页面，获取教师详情', teacherId);
      // 确保每次进入页面时都重新获取最新数据
      fetchTeacherDetails(teacherId);
    }
    
    // 组件卸载时清理工作
    return () => {
      console.log('TeacherBasicInfo: 组件卸载');
      // 可以在这里添加清理逻辑
    };
  }, [teacherId, fetchTeacherDetails]);
  
  // 表单初始化 - 确保每次selectedTeacher更新时重新设置表单值
  useEffect(() => {
    if (selectedTeacher) {
      console.log('TeacherBasicInfo: selectedTeacher更新，设置表单值', selectedTeacher);
      const baseInfo = selectedTeacher.baseInfo || {};
      
      // 完整设置所有表单字段
      form.setFieldsValue({
        name: selectedTeacher.name,
        gender: baseInfo.gender,
        birthDate: baseInfo.birthDate ? moment(baseInfo.birthDate) : undefined,
        teachingYears: baseInfo.teachingYears,
        highestEducation: baseInfo.highestEducation,
        personalStatement: baseInfo.personalStatement
      });
      
      // 更新头像和视频URL状态
      setAvatarUrl(baseInfo.avatar || '');
      setVideoUrl(baseInfo.videoIntro || '');
    }
  }, [selectedTeacher, form]);
  
  // 处理返回按钮
  const handleBack = () => {
    navigate('/admin/dashboard');
  };
  
  // 处理表单提交
  const handleSubmit = async (values: any) => {
    if (!teacherId) return;
    
    try {
      setSaving(true);
      
      // 准备要发送的数据
      const baseInfoData: Partial<TeacherBaseInfo> = {
        gender: values.gender,
        birthDate: values.birthDate ? values.birthDate.format('YYYY-MM-DD') : undefined,
        teachingYears: values.teachingYears,
        highestEducation: values.highestEducation,
        personalStatement: values.personalStatement,
        avatar: avatarUrl,
        videoIntro: videoUrl
      };
      
      console.log('即将保存的基本信息数据:', baseInfoData);
      
      // 显示加载提示
      const hideLoading = message.loading('正在保存基本信息...', 0);
      
      // 发送请求更新数据
      const success = await updateTeacherBaseInfo(teacherId, baseInfoData);
      
      // 隐藏加载提示
      hideLoading();
      
      if (success) {
        // 显示成功消息
        message.success('教师基本信息保存成功');
        
        // 立即重新获取完整数据，确保数据一致性
        console.log('重新获取教师基本信息以确保数据一致性');
        const updatedTeacher = await fetchTeacherDetails(teacherId);
        
        if (updatedTeacher && updatedTeacher.baseInfo) {
          console.log('已更新的教师基本信息:', updatedTeacher.baseInfo);
          
          // 确保表单显示的是最新的数据
          form.setFieldsValue({
            name: updatedTeacher.name,
            gender: updatedTeacher.baseInfo.gender,
            birthDate: updatedTeacher.baseInfo.birthDate ? moment(updatedTeacher.baseInfo.birthDate) : undefined,
            teachingYears: updatedTeacher.baseInfo.teachingYears,
            highestEducation: updatedTeacher.baseInfo.highestEducation,
            personalStatement: updatedTeacher.baseInfo.personalStatement
          });
          
          // 更新头像和视频URL状态
          setAvatarUrl(updatedTeacher.baseInfo.avatar || '');
          setVideoUrl(updatedTeacher.baseInfo.videoIntro || '');
        }
      }
    } catch (error) {
      console.error('保存基本信息出错', error);
      message.error('保存失败，请重试');
    } finally {
      setSaving(false);
    }
  };
  
  // 处理头像上传
  const handleAvatarUpload = async (file: RcFile) => {
    try {
      setUploadingAvatar(true);
      
      const response = await fileAPI.uploadFile(file);
      if (response.data && response.data.url) {
        setAvatarUrl(response.data.url);
        message.success('头像上传成功');
      } else {
        message.error('头像上传失败');
      }
    } catch (error) {
      console.error('上传头像出错', error);
      message.error('头像上传失败，请重试');
    } finally {
      setUploadingAvatar(false);
    }
    
    return false; // 阻止默认上传行为
  };
  
  // 处理视频上传
  const handleVideoUpload = async (file: RcFile) => {
    // 检查文件大小（小于1.8GB）
    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > 1800) {
      message.error('视频文件过大，请上传小于1.8GB的文件');
      return false;
    }
    
    try {
      setUploadingVideo(true);
      setIsUploading(true);
      setUploadProgress(0);
      
      const response = await fileAPI.uploadVideo(file, (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      
      if (response.data && response.data.url) {
        setVideoUrl(response.data.url);
        message.success('视频上传成功');
      } else {
        message.error('视频上传失败');
      }
    } catch (error: any) {
      console.error('上传视频出错', error);
      if (error.code === 'ECONNABORTED') {
        message.error('视频上传超时，请尝试上传较小的文件或检查网络连接');
      } else if (error.response && error.response.status === 413) {
        message.error('视频文件过大，超出服务器限制');
      } else {
        message.error('视频上传失败，请重试');
      }
    } finally {
      setUploadingVideo(false);
      setIsUploading(false);
    }
    
    return false; // 阻止默认上传行为
  };
  
  if (loading && !selectedTeacher) {
    return (
      <div className="loading-container">
        <Spin size="large" />
        <p>加载中...</p>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="error-container">
        <Alert type="error" message="加载失败" description={error} />
        <Button onClick={handleBack} icon={<ArrowLeftOutlined />} style={{ marginTop: 16 }}>
          返回
        </Button>
      </div>
    );
  }
  
  if (!selectedTeacher) {
    return (
      <div className="error-container">
        <Alert type="warning" message="教师不存在" description="未找到该教师信息，请返回重试" />
        <Button onClick={handleBack} icon={<ArrowLeftOutlined />} style={{ marginTop: 16 }}>
          返回
        </Button>
      </div>
    );
  }
  
  return (
    <div className="teacher-form-container">
      <div className="page-header">
        <Button 
          icon={<ArrowLeftOutlined />} 
          onClick={handleBack}
          style={{ marginRight: 16 }}
        >
          返回
        </Button>
        <Title level={4} className="page-title">编辑教师基本信息</Title>
      </div>
      
      <Card className="form-card">
        {selectedTeacher && (
          <div className="teacher-info-heading">
            <Title level={5}>教师信息: {selectedTeacher.name}</Title>
            <Divider />
          </div>
        )}
        
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Row gutter={[24, 12]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="name"
                label="姓名"
              >
                <Input disabled style={{ backgroundColor: '#f5f5f5', color: '#000000' }} />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="gender"
                label="性别"
                rules={[{ required: true, message: '请选择性别' }]}
              >
                <Select placeholder="请选择">
                  <Option value="MALE">男</Option>
                  <Option value="FEMALE">女</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={[24, 12]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="birthDate"
                label="出生年月"
                rules={[{ required: true, message: '请选择出生年月' }]}
              >
                <DatePicker 
                  picker="month" 
                  style={{ width: '100%' }}
                  placeholder="请选择"
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="teachingYears"
                label="教龄"
                rules={[{ required: true, message: '请填写教龄' }]}
              >
                <Input placeholder="例如：5年" />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={[24, 12]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="highestEducation"
                label="最高学历"
                rules={[{ required: true, message: '请选择最高学历' }]}
              >
                <Select placeholder="请选择">
                  <Option value="BACHELOR">本科</Option>
                  <Option value="MASTER">硕士</Option>
                  <Option value="DOCTOR">博士</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item
            name="personalStatement"
            label="个人陈述"
            rules={[{ required: true, message: '请填写个人陈述' }]}
          >
            <TextArea rows={4} placeholder="请填写个人陈述" />
          </Form.Item>
          
          <Divider orientation="left">头像和视频</Divider>
          
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="教师头像"
                required
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  {avatarUrl && (
                    <div className="avatar-preview">
                      <img src={avatarUrl} alt="教师头像" className="responsive-image" />
                    </div>
                  )}
                  
                  <Upload
                    name="avatar"
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={handleAvatarUpload}
                  >
                    <Button 
                      icon={uploadingAvatar ? <LoadingOutlined /> : <UploadOutlined />}
                      loading={uploadingAvatar}
                      className="upload-button"
                    >
                      {avatarUrl ? '更新头像' : '上传头像'}
                    </Button>
                  </Upload>
                </Space>
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="教师介绍视频"
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  {videoUrl && (
                    <div className="video-preview">
                      <video src={videoUrl} controls width="100%" className="responsive-video" />
                    </div>
                  )}
                  
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Upload
                      name="video"
                      accept="video/*"
                      showUploadList={false}
                      beforeUpload={handleVideoUpload}
                      disabled={isUploading}
                    >
                      <Button 
                        icon={uploadingVideo ? <LoadingOutlined /> : <UploadOutlined />}
                        loading={uploadingVideo}
                        disabled={isUploading}
                      >
                        {videoUrl ? '更新视频' : '上传视频'}
                      </Button>
                    </Upload>
                    
                    {isUploading && (
                      <div>
                        <Progress percent={uploadProgress} status="active" />
                        <div className="upload-status-text">
                          正在上传视频，请勿关闭窗口...
                        </div>
                      </div>
                    )}
                    
                    <div className="upload-help-text">
                      支持的视频格式：MP4, MOV, AVI 等。文件大小限制：1.8GB
                    </div>
                  </Space>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item>
            <div className="form-actions">
              <Button 
                onClick={handleBack}
                style={{ marginRight: 8 }}
              >
                取消
              </Button>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={saving}
              >
                保存
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default TeacherBasicInfo; 
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Switch, Modal, Divider, Spin, Tabs, message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import './HomePage.css';
import { teacherAPI } from '../services/api';

// TypeScript declaration for the window property
declare global {
  interface Window {
    currentTeacherRequest: CancelTokenSource | null;
  }
}

// Initialize the property
window.currentTeacherRequest = null;

// 视频播放组件
interface VideoPlayerProps {
  videoUrl: string;
  isEnglish: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, isEnglish }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Reset states when video URL changes
    setIsLoading(true);
  }, [videoUrl]);

  // 检测URL是否有效
  if (!videoUrl) {
    console.error('Invalid video URL: URL is empty');
    return (
      <div className="video-error">
        <p>{isEnglish ? 'Video URL is invalid or unavailable.' : '视频链接无效或不可用。'}</p>
      </div>
    );
  }

  try {
    // 检测是否是YouTube链接
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    // 检测是否是Vimeo链接
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
    // 检测是否是腾讯云COS链接
    const tencentCosRegex = /^https?:\/\/[^/]+\.cos\.[^/]+\.myqcloud\.com\/.+/i;
    // 检测是否是其他常见视频格式链接
    const videoFileRegex = /\.(mp4|webm|ogg|mov)(\?.*)?$/i;
    
    // 日志记录用于调试
    console.log('Processing video URL:', videoUrl);
    
    if (youtubeRegex.test(videoUrl)) {
      console.log('Detected YouTube URL');
      // 从YouTube URL中提取视频ID
      let videoId = '';
      if (videoUrl.includes('youtube.com/watch?v=')) {
        videoId = videoUrl.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
          videoId = videoId.substring(0, ampersandPosition);
        }
      } else if (videoUrl.includes('youtu.be/')) {
        videoId = videoUrl.split('youtu.be/')[1];
      }
      
      if (!videoId) {
        console.error('Failed to extract YouTube video ID from URL:', videoUrl);
        return (
          <div className="video-error">
            <p>{isEnglish ? 'Failed to process YouTube URL.' : '处理YouTube链接失败。'}</p>
          </div>
        );
      }
      
      return (
        <>
          <div className={`video-container youtube-container ${isLoading ? 'loading' : ''} ${isEnglish ? 'en' : 'zh'}`}>
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
              }}
            ></iframe>
          </div>
        </>
      );
    } else if (vimeoRegex.test(videoUrl)) {
      console.log('Detected Vimeo URL');
      // 从Vimeo URL中提取视频ID
      let videoId = '';
      if (videoUrl.includes('vimeo.com/')) {
        videoId = videoUrl.split('vimeo.com/')[1];
      }
      
      if (!videoId) {
        console.error('Failed to extract Vimeo video ID from URL:', videoUrl);
        return (
          <div className="video-error">
            <p>{isEnglish ? 'Failed to process Vimeo URL.' : '处理Vimeo链接失败。'}</p>
          </div>
        );
      }
      
      return (
        <>
          <div className={`video-container vimeo-container ${isLoading ? 'loading' : ''} ${isEnglish ? 'en' : 'zh'}`}>
            <iframe
              src={`https://player.vimeo.com/video/${videoId}`}
              width="100%"
              height="315"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Vimeo video player"
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
              }}
            ></iframe>
          </div>
        </>
      );
    } else if (tencentCosRegex.test(videoUrl) || videoFileRegex.test(videoUrl)) {
      // 处理腾讯云COS或一般视频文件链接
      console.log('Using direct video player for Tencent COS or video file:', videoUrl);
      
      // 确定视频类型，默认为mp4
      let videoType = 'video/mp4';
      if (videoUrl.includes('.mp4')) {
        videoType = 'video/mp4';
      } else if (videoUrl.includes('.webm')) {
        videoType = 'video/webm';
      } else if (videoUrl.includes('.ogg')) {
        videoType = 'video/ogg';
      } else if (videoUrl.includes('.mov')) {
        videoType = 'video/quicktime';
      }
      
      return (
        <>
          <div className={`video-container direct-video-container ${isLoading ? 'loading' : ''} ${isEnglish ? 'en' : 'zh'}`}>
            <video 
              controls 
              src={videoUrl} 
              className="teacher-video" 
              onLoadedData={() => setIsLoading(false)}
              onError={(e) => {
                console.error('Video failed to load:', e, videoUrl);
                setIsLoading(false);
                const target = e.target as HTMLVideoElement;
                target.onerror = null;
                target.poster = "./images/video-error.jpg";
              }}
              poster="./images/video-loading.jpg"
              controlsList="nodownload"
              playsInline
              preload="metadata"
              autoPlay={false}
            >
              <source src={videoUrl} type={videoType} />
              <p>{isEnglish ? 'Your browser does not support the video tag.' : '您的浏览器不支持视频标签。'}</p>
            </video>
          </div>
        </>
      );
    } else {
      console.log('Using fallback video player for URL:', videoUrl);
      // 对于其他无法识别的URL，使用通用视频播放器
      return (
        <>
          <div className={`video-container ${isLoading ? 'loading' : ''} ${isEnglish ? 'en' : 'zh'}`}>
            <video 
              controls 
              className="teacher-video" 
              onLoadedData={() => setIsLoading(false)}
              onError={(e) => {
                console.error('Video failed to load:', e, videoUrl);
                setIsLoading(false);
                const target = e.target as HTMLVideoElement;
                target.onerror = null;
                target.poster = "./images/video-error.jpg";
              }}
              poster="./images/video-loading.jpg"
              controlsList="nodownload"
              playsInline
              preload="metadata"
            >
              <source src={videoUrl} type="video/mp4" />
              <source src={videoUrl} type="video/webm" />
              <source src={videoUrl} type="video/ogg" />
              <p>{isEnglish ? 'Your browser does not support the video tag.' : '您的浏览器不支持视频标签。'}</p>
            </video>
          </div>
        </>
      );
    }
  } catch (error) {
    console.error('Error processing video URL:', error, videoUrl);
    return (
      <div className="video-error">
        <p>{isEnglish ? 'Error processing video. Please try again later.' : '处理视频时出错，请稍后再试。'}</p>
      </div>
    );
  }
};

interface Teacher {
  id: string;
  chineseName: string;
  englishName: string;
  yearsOfExperience: number;
  educationLevel: string;
  age: number;
  profileImage: string;
  // 基本信息
  gender?: 'MALE' | 'FEMALE';
  birthDate?: string;
  personalStatement?: string;
  videoIntro?: string;
  // 教育背景信息
  educationInfo?: {
    id?: number;
    schoolName: string;
    startDate: string;
    endDate?: string;
    majorName?: string;
    educationType: string;
    proofImage?: string;
    description?: string;
  }[];
  // 语言能力
  languageAbility?: {
    id?: number;
    englishLevel?: string;
    standardizedScores?: string;
    otherLanguages?: string;
  };
  // 专业证书
  certificates?: {
    id?: number;
    obtainYear?: number;
    certificateName: string;
    issuingAuthority?: string;
    certificateImage?: string;
  }[];
  // 教学经历
  teachingExperience?: {
    id?: number;
    startDate: string;
    endDate?: string;
    institutionName: string;
    position: string;
    jobDescription?: string;
    isCurrent?: boolean;
  }[];
  // 教学内容
  teachingContent?: {
    id?: number;
    subject: string;
    description?: string;
  }[];
  // 课时标准
  classHourStandards?: {
    id?: number;
    type: string;
    standard: string;
  }[];
  // 教学成果
  teachingResults?: {
    id?: number;
    studentName: string;
    startLearningDate: string;
    achievementDate: string;
    achievementDetails?: string;
  }[];
  // 其他信息
  introduction?: string;
  specialties?: string[];
  teachingAreas?: string[];
}

const HomePage: React.FC = () => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [isEnglish, setIsEnglish] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher | null>(null);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [showAll, setShowAll] = useState(false);
  const [qrCodeVisible, setQrCodeVisible] = useState<boolean>(false);
  const [previewImageVisible, setPreviewImageVisible] = useState<boolean>(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>('');
  const [previewImageTitle, setPreviewImageTitle] = useState<string>('');
  const [welcomeModalVisible, setWelcomeModalVisible] = useState<boolean>(false);
  const TEACHER_LIMIT = 6; // 默认显示6位教师
  const navigate = useNavigate();
  // 添加教师详情缓存
  const teacherDetailsCache = useRef<Record<string, {
    data: Teacher;
    timestamp: number;
  }>>({});
  // 缓存有效期（毫秒）- 5分钟
  const CACHE_TTL = 5 * 60 * 1000;

  useEffect(() => {
    // 从API加载教师数据
    const fetchTeachers = async () => {
      try {
        setLoading(true);
        // 实际项目中替换为真实API
        const response = await axios.get('/api/teachers');
        const teachersList = response.data;
        
        // 为每个教师加载基本信息（包括性别）
        const teachersWithGender = await Promise.all(
          teachersList.map(async (teacher: Teacher) => {
            try {
              // 创建一个可取消的请求
              const cancelTokenSource = axios.CancelToken.source();
              const baseInfoResponse = await teacherAPI.getBaseInfo(
                Number(teacher.id), 
                { cancelToken: cancelTokenSource.token }
              );
              
              // 将性别信息添加到教师对象中
              if (baseInfoResponse.data && baseInfoResponse.data.gender) {
                return {
                  ...teacher,
                  gender: baseInfoResponse.data.gender
                };
              }
              return teacher;
            } catch (error) {
              console.error(`Error fetching gender for teacher ${teacher.id}:`, error);
              return teacher;
            }
          })
        );
        
        setTeachers(teachersWithGender);
      } catch (error) {
        console.error('Error fetching teachers:', error);
        // 删除测试数据
        setTeachers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  // 添加欢迎弹窗逻辑
  useEffect(() => {
    // 显示欢迎弹窗，每次刷新页面都会显示
    setWelcomeModalVisible(true);
  }, []);

  // 清理函数 - 组件卸载时取消所有未完成的请求
  useEffect(() => {
    return () => {
      // 组件卸载时，取消所有正在进行的请求
      if (window.currentTeacherRequest) {
        console.log('组件卸载，取消所有未完成的请求');
        window.currentTeacherRequest.cancel('Component unmounted');
        window.currentTeacherRequest = null;
      }
    };
  }, []);

  const handleLogin = () => {
    navigate('/login');
  };

  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  const handleTeacherClick = async (teacher: Teacher) => {
    // Cancel any ongoing requests for other teachers
    if (window.currentTeacherRequest) {
      console.log('取消之前的教师详情请求');
      window.currentTeacherRequest.cancel('Cancelled due to new teacher selection');
    }
    
    // Create a new cancellation token
    const cancelTokenSource = axios.CancelToken.source();
    window.currentTeacherRequest = cancelTokenSource;
    
    // 首先从缓存中检查是否有此教师的详情数据，以及数据是否在有效期内
    const cachedTeacher = teacherDetailsCache.current[teacher.id];
    const now = Date.now();
    const isCacheValid = cachedTeacher && (now - cachedTeacher.timestamp < CACHE_TTL);
    
    if (isCacheValid) {
      // 使用缓存数据
      console.log('使用缓存的教师详情数据:', teacher.id);
      setSelectedTeacher(cachedTeacher.data);
      setDetailModalVisible(true);
      return;
    }
    
    // 如果没有缓存或缓存已过期，设置初始教师数据并显示模态框
    setSelectedTeacher(teacher);
    setDetailModalVisible(true);
    
    // 如果已经有详细信息，则不需要再次获取
    if (teacher.educationInfo && teacher.languageAbility && teacher.certificates && 
        teacher.teachingExperience && teacher.teachingContent && 
        teacher.classHourStandards && teacher.teachingResults) {
      // 虽然已有详情，但仍然更新缓存以刷新时间戳
      teacherDetailsCache.current[teacher.id] = {
        data: teacher,
        timestamp: now
      };
      return;
    }
    
    // 点击卡片时加载教师的完整详细信息
    try {
      setLoadingDetails(true);
      
      // 获取各种详细信息
      const teacherId = Number(teacher.id);
      
      // 准备一个更新后的教师对象
      const updatedTeacher = { ...teacher };
      
      // 为所有请求添加取消令牌
      const cancelConfig = { cancelToken: cancelTokenSource.token };
      
      // 使用Promise.all同时发起多个请求，提高加载速度
      const [
        baseInfoResponse,
        educationsResponse,
        languageResponse,
        certificatesResponse,
        experiencesResponse,
        contentsResponse,
        standardsResponse,
        resultsResponse
      ] = await Promise.all([
        teacherAPI.getBaseInfo(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取教师基本信息失败:', error);
          }
          return { data: null };
        }),
        teacherAPI.getEducationInfoList(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取教育背景信息失败:', error);
          }
          return { data: [] };
        }),
        teacherAPI.getLanguageAbilityInfo(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取语言能力信息失败:', error);
          }
          return { data: null };
        }),
        teacherAPI.getCertificateInfoList(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取证书信息失败:', error);
          }
          return { data: [] };
        }),
        teacherAPI.getTeachingExpInfoList(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取教学经历信息失败:', error);
          }
          return { data: [] };
        }),
        teacherAPI.getTeachingContentInfoList(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取教学内容信息失败:', error);
          }
          return { data: [] };
        }),
        teacherAPI.getClassHourStandardInfoList(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取课时标准信息失败:', error);
          }
          return { data: [] };
        }),
        teacherAPI.getTeachingResultInfoList(teacherId, cancelConfig).catch(error => {
          if (axios.isCancel(error)) {
            console.log('请求已取消:', error.message);
          } else {
            console.error('获取教学成果信息失败:', error);
          }
          return { data: [] };
        })
      ]);
      
      // Clear the current request reference if it completed successfully
      if (window.currentTeacherRequest === cancelTokenSource) {
        window.currentTeacherRequest = null;
      }

      // 更新教师信息对象
      if (baseInfoResponse.data) {
        updatedTeacher.gender = baseInfoResponse.data.gender;
        updatedTeacher.birthDate = baseInfoResponse.data.birthDate;
        updatedTeacher.personalStatement = baseInfoResponse.data.personalStatement;
        updatedTeacher.videoIntro = baseInfoResponse.data.videoIntro;
      }
      
      updatedTeacher.educationInfo = educationsResponse.data || [];
      updatedTeacher.languageAbility = languageResponse.data || null;
      updatedTeacher.certificates = certificatesResponse.data || [];
      updatedTeacher.teachingExperience = experiencesResponse.data || [];
      updatedTeacher.teachingContent = contentsResponse.data || [];
      updatedTeacher.classHourStandards = standardsResponse.data || [];
      updatedTeacher.teachingResults = resultsResponse.data || [];
      
      // 更新选中的教师信息
      setSelectedTeacher(updatedTeacher);
      
      // 更新缓存
      teacherDetailsCache.current[teacher.id] = {
        data: updatedTeacher,
        timestamp: Date.now()
      };
      
      // 同时更新 teachers 数组中的对应教师信息
      setTeachers(prevTeachers => 
        prevTeachers.map(t => 
          t.id === teacher.id ? updatedTeacher : t
        )
      );
    } catch (error) {
      console.error('Error fetching teacher details:', error);
      message.error(isEnglish ? 'Failed to load some teacher details' : '加载部分教师详情失败');
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleCloseModal = () => {
    setDetailModalVisible(false);
  };

  const handleShowQRCode = () => {
    setQrCodeVisible(true);
  };

  const handleCloseQRCode = () => {
    setQrCodeVisible(false);
  };

  const handleImagePreview = (imageUrl: string | undefined, title: string) => {
    if (!imageUrl) return; // 如果imageUrl是undefined，直接返回
    setPreviewImageUrl(imageUrl);
    setPreviewImageTitle(title);
    setPreviewImageVisible(true);
  };

  const handleCloseImagePreview = () => {
    setPreviewImageVisible(false);
  };

  // 关闭欢迎弹窗
  const handleCloseWelcomeModal = () => {
    setWelcomeModalVisible(false);
  };

  return (
    <div className="home-container">
      {/* 顶部区域 */}
      <header className="home-header">
        <div className="header-content">
          <div className="logo-container">
            <img src="images/logo.png" alt="Teacher Logo" className="logo" />
          </div>
          <div className="language-login">
            <div className="language-toggle">
              <span className={!isEnglish ? 'active-lang' : ''}>中文</span>
              <Switch
                checked={isEnglish}
                onChange={toggleLanguage}
                size="small"
                className="language-switch"
              />
              <span className={isEnglish ? 'active-lang' : ''}>English</span>
            </div>
            <Button type="primary" onClick={handleLogin} className="login-button">
              {isEnglish ? 'Login' : '登录'}
            </Button>
          </div>
        </div>
      </header>

      {/* 背景区域 */}
      <div className="background-container">
        <picture>
          <source media="(max-width: 768px)" srcSet="images/mobile-background.png" />
          <img 
            src="images/desktop-background.png" 
            alt="背景图片" 
            className="background-image" 
          />
        </picture>
      </div>

      <div className="section-title-container">
        <picture>
          <source media="(max-width: 768px)" srcSet="images/mobile_title.png" />
          <img 
            src="images/desktop_title.png" 
            alt={isEnglish ? 'Our Teachers' : '我们的教师'} 
            className="section-title-image" 
          />
        </picture>
      </div>

      {/* 主内容区域 - 教师信息展示 */}
      <main className="main-content-area">
        
        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div className="teachers-grid">
              {(showAll ? teachers : teachers.slice(0, TEACHER_LIMIT)).map(teacher => (
                <div 
                  key={teacher.id} 
                  className="teacher-card" 
                  onClick={() => handleTeacherClick(teacher)}
                >
                  <div className="teacher-header">
                    <div className="teacher-info-container">
                      <h3 className="teacher-name">
                        {teacher.chineseName}
                      </h3>
                      <div className="teacher-basic-info">
                        <div className="info-item">
                          <span className="info-icon"><i className="fas fa-id-badge"></i></span>
                          <span className="info-text">{teacher.yearsOfExperience}{isEnglish ? ' years' : '年'}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-icon"><i className="fas fa-graduation-cap"></i></span>
                          <span className="info-text">{teacher.educationLevel}</span>
                        </div>
                      </div>
                    </div>
                    <div className="teacher-image-container">
                      <img 
                        src={teacher.profileImage} 
                        alt={teacher.chineseName} 
                        className="teacher-image"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = "./images/default-teacher.jpg";
                        }}
                      />
                      <span className={`gender-icon ${(teacher.gender || 'MALE') === 'FEMALE' ? 'female' : 'male'}`}>
                        {(teacher.gender || 'MALE') === 'FEMALE' ? '♀' : '♂'}
                      </span>
                    </div>
                  </div>
                  
                  {teacher.educationInfo && teacher.educationInfo.length > 0 && (
                    <div className="teacher-education">
                      <h4 className="section-heading">教育背景</h4>
                      {teacher.educationInfo.map((edu, index) => (
                        <div key={index} className="edu-item">
                          <div className="edu-period-school">
                            <span className="edu-period">
                              {moment(edu.startDate).format('YYYY.MM')} — {edu.endDate ? moment(edu.endDate).format('YYYY.MM') : '至今'}
                            </span>
                            <span className="edu-school">{edu.schoolName}</span>
                          </div>
                          <div className="edu-degree">
                            {edu.educationType === 'PRIMARY_SCHOOL'
                              ? '小学'
                              : edu.educationType === 'MIDDLE_SCHOOL'
                                ? '初中'
                                : edu.educationType === 'HIGH_SCHOOL' 
                                  ? '高中' 
                                  : edu.educationType === 'BACHELOR' 
                                    ? '本科' 
                                    : edu.educationType === 'MASTER' 
                                      ? '硕士' 
                                      : edu.educationType === 'DOCTOR' 
                                        ? '博士' 
                                        : edu.educationType
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            
            {teachers.length > TEACHER_LIMIT && (
              <div className="show-more-container">
                <button 
                  className="show-more-button" 
                  onClick={(e) => {
                    // 添加点击波纹效果
                    const button = e.currentTarget;
                    button.classList.add('button-clicked');
                    // 移除波纹效果类（允许重复点击触发动画）
                    setTimeout(() => {
                      button.classList.remove('button-clicked');
                      // 移除焦点，确保按钮不会保持蓝色状态
                      button.blur();
                    }, 150); // 150毫秒，与CSS动画时间一致
                    // 切换显示状态
                    setShowAll(!showAll);
                  }}
                >
                  {showAll 
                    ? (isEnglish ? 'Collapse' : '收起') 
                    : (isEnglish ? 'View More Teachers' : '查看更多教师')
                  }
                </button>
              </div>
            )}
          </>
        )}
      </main>

      {/* 底部区域 */}
      <footer className="home-footer">
        <div className="footer-content">
          <div className="footer-sections">
            <div className="footer-section">
              <h4>{isEnglish ? 'About Us' : '关于我们'}</h4>
              <p>
                {isEnglish ? 'We are committed to providing the best teaching resources and connecting excellent teachers with students.' : 'Teacher私下聊平台汇聚精英教师，提供专业的英语教学服务，帮助学生提示语言能力，实现学业目标。'}
              </p>
            </div>

            <div className="footer-section">
              <h4>{isEnglish ? 'Contact Us' : '商务联系'}</h4>
              <p>
                {isEnglish ? 'Email: ' : '邮箱: '}17165663@qq.com      {isEnglish ? 'Phone: ' : '电话: '}187-8223-2960
              </p>
            </div>

            <div className="footer-section">
              <h4>{isEnglish ? 'Legal Information' : '法律信息'}</h4>
              <div className="legal-links">
                <a href="/privacy">{isEnglish ? 'Privacy Policy' : '用户协议'}</a>
                <a href="/terms">{isEnglish ? 'Terms of Service' : '隐私政策'}</a>
                <a href="/copyright">{isEnglish ? 'Copyright Notice' : '免责声明'}</a>
              </div>
            </div>
          </div>
          
          <div className="copyright">
            © 2025 Teacher私下聊. {isEnglish ? 'All rights reserved.' : '保留所有权利'}
          </div>
        </div>
      </footer>
      
      {/* 教师详细信息模态框 */}
      <Modal
        title={selectedTeacher ? (isEnglish ? `${selectedTeacher.englishName || selectedTeacher.chineseName}'s Detailed Information` : `${selectedTeacher.chineseName}的详细信息`) : ''}
        open={detailModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
        className="teacher-detail-modal"
      >
        {loadingDetails ? (
          <div className="loading-details-container">
            <Spin size="large" />
            <p>{isEnglish ? 'Loading teacher details...' : '正在加载教师详情...'}</p>
          </div>
        ) : (
          selectedTeacher && (
            <div className="teacher-detail-content">
              <div className="teacher-detail-header">
                <div className={`teacher-detail-image-container ${(selectedTeacher.gender || 'FEMALE') === 'MALE' ? 'male' : ''}`}>
                  <img 
                    src={selectedTeacher.profileImage} 
                    alt={selectedTeacher.chineseName}
                    className="teacher-detail-image"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = "./images/default-teacher.jpg";
                    }}
                  />
                </div>
                <div className="teacher-detail-info">
                  <h2>{selectedTeacher.chineseName}</h2>
                  <div className="teacher-detail-basic-info">
                    <div className="teacher-detail-info-item">
                      <span className="teacher-detail-info-icon"><i className="fas fa-id-badge"></i></span>
                      <span className="teacher-detail-info-text">{selectedTeacher.yearsOfExperience}年</span>
                    </div>
                    <div className="teacher-detail-info-item">
                      <span className="teacher-detail-info-icon"><i className="fas fa-graduation-cap"></i></span>
                      <span className="teacher-detail-info-text">{selectedTeacher.educationLevel}</span>
                    </div>
                    <div className="teacher-detail-info-item">
                      <span className="teacher-detail-info-icon"><i className="fas fa-birthday-cake"></i></span>
                      <span className="teacher-detail-info-text">{selectedTeacher.age}岁</span>
                    </div>
                  </div>
                </div>
                <div className="teacher-detail-button-container">
                  <Button 
                    type="primary" 
                    className="chat-teacher-button" 
                    onClick={handleShowQRCode}
                  >
                    {isEnglish ? 'Chat with Teacher' : '私聊老师'}
                  </Button>
                </div>
              </div>
              
              <Divider />
              
              <div className="teacher-detail-sections">
                {/* 1. 个人陈述 */}
                {selectedTeacher.personalStatement && (
                  <div className="detail-section personal-statement">
                    <h3>{isEnglish ? 'Personal Statement' : '个人陈述'}</h3>
                    <p>{selectedTeacher.personalStatement}</p>
                  </div>
                )}
                
                {/* 2. 教师介绍视频 */}
                {selectedTeacher.videoIntro && (
                  <div className="detail-section video-intro">
                    <h3>{isEnglish ? 'Teacher Introduction Video' : '教师视频介绍'}</h3>
                    <VideoPlayer videoUrl={selectedTeacher.videoIntro} isEnglish={isEnglish} />
                  </div>
                )}
                
                {/* 3. 教育背景 */}
                {selectedTeacher.educationInfo && selectedTeacher.educationInfo.length > 0 && (
                  <div className="detail-section education-background">
                    <h3>{isEnglish ? 'Education' : '教育背景'}</h3>
                    <div className="education-list">
                      {selectedTeacher.educationInfo.map((edu, index) => (
                        <div key={index} className="edu-item-detail">
                          <p><strong>{edu.schoolName}</strong></p>
                          <p>{moment(edu.startDate).format('YYYY.MM')} — {edu.endDate ? moment(edu.endDate).format('YYYY.MM') : isEnglish ? 'Present' : '至今'}</p>
                          <p>
                            {isEnglish 
                              ? edu.educationType 
                              : edu.educationType === 'PRIMARY_SCHOOL'
                                ? '小学'
                                : edu.educationType === 'MIDDLE_SCHOOL'
                                  ? '初中'
                                  : edu.educationType === 'HIGH_SCHOOL' 
                                    ? '高中' 
                                    : edu.educationType === 'BACHELOR' 
                                      ? '本科' 
                                      : edu.educationType === 'MASTER' 
                                        ? '硕士' 
                                        : edu.educationType === 'DOCTOR' 
                                          ? '博士' 
                                          : edu.educationType
                            }
                          </p>
                          {edu.majorName && <p>{isEnglish ? 'Major' : '专业'}: {edu.majorName}</p>}
                          {edu.description && <p>{edu.description}</p>}
                          {edu.proofImage && (
                            <div className="education-proof">
                              <p>{isEnglish ? 'Education Certificate' : '教育背景证书'}:</p>
                              <img 
                                src={edu.proofImage} 
                                alt={isEnglish ? `${edu.schoolName} Certificate` : `${edu.schoolName}证书`} 
                                className="education-proof-image" 
                                onClick={() => handleImagePreview(edu.proofImage, isEnglish ? `${edu.schoolName} Certificate` : `${edu.schoolName}证书`)}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* 4. 语言能力 */}
                {selectedTeacher.languageAbility && (
                  (selectedTeacher.languageAbility.englishLevel || 
                   selectedTeacher.languageAbility.standardizedScores || 
                   selectedTeacher.languageAbility.otherLanguages) && (
                    <div className="detail-section language-skills">
                      <h3>{isEnglish ? 'Language Skills' : '语言能力'}</h3>
                      <div className="language-ability">
                        {selectedTeacher.languageAbility.englishLevel && (
                          <div className="ability-item">
                            <strong>{isEnglish ? 'English Level' : '英语水平'}:</strong> 
                            <span>{selectedTeacher.languageAbility.englishLevel}</span>
                          </div>
                        )}
                        {selectedTeacher.languageAbility.standardizedScores && (
                          <div className="ability-item">
                            <strong>{isEnglish ? 'Standardized Scores' : '标准化考试成绩'}:</strong> 
                            <span>{selectedTeacher.languageAbility.standardizedScores}</span>
                          </div>
                        )}
                        {selectedTeacher.languageAbility.otherLanguages && (
                          <div className="ability-item">
                            <strong>{isEnglish ? 'Other Languages' : '其他语言'}:</strong> 
                            <span>{selectedTeacher.languageAbility.otherLanguages}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
                
                {/* 5. 专业证书 */}
                {selectedTeacher.certificates && selectedTeacher.certificates.length > 0 && (
                  <div className="detail-section certificates">
                    <h3>{isEnglish ? 'Certificates' : '专业证书'}</h3>
                    <div className="certificates-list">
                      {selectedTeacher.certificates.map((cert, index) => (
                        <div key={index} className="certificate-item">
                          <p><strong>{cert.certificateName}</strong> ({cert.obtainYear})</p>
                          {cert.issuingAuthority && <p>{isEnglish ? 'Issuing Authority' : '颁发机构'}: {cert.issuingAuthority}</p>}
                          {cert.certificateImage && (
                            <div className="certificate-image">
                              <img 
                                src={cert.certificateImage} 
                                alt={cert.certificateName} 
                                onClick={() => handleImagePreview(cert.certificateImage, cert.certificateName)}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* 6. 教学经历 */}
                {selectedTeacher.teachingExperience && selectedTeacher.teachingExperience.length > 0 && (
                  <div className="detail-section teaching-experience">
                    <h3>{isEnglish ? 'Teaching Experience' : '教学经历'}</h3>
                    <div className="experience-list">
                      {selectedTeacher.teachingExperience.map((exp, index) => (
                        <div key={index} className="experience-item">
                          <p><strong>{exp.institutionName}</strong> - {exp.position}</p>
                          <p>{moment(exp.startDate).format('YYYY.MM')} — {exp.endDate ? moment(exp.endDate).format('YYYY.MM') : isEnglish ? 'Present' : '至今'}</p>
                          {exp.jobDescription && <p>{exp.jobDescription}</p>}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* 7. 教学内容 */}
                {selectedTeacher.teachingContent && selectedTeacher.teachingContent.length > 0 && (
                  <div className="detail-section teaching-content">
                    <h3>{isEnglish ? 'Teaching Content' : '教学内容'}</h3>
                    <div className="teaching-content-list">
                      {selectedTeacher.teachingContent.map((content, index) => (
                        <div key={index} className="teaching-content-item">
                          <p><strong>{content.subject}</strong></p>
                          {content.description && <p>{content.description}</p>}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* 8. 课时标准 */}
                {selectedTeacher.classHourStandards && selectedTeacher.classHourStandards.length > 0 && (
                  <div className="detail-section class-hour-standards">
                    <h3>{isEnglish ? 'Class Hour Standards' : '课时标准'}</h3>
                    <div className="class-hour-grid">
                      {selectedTeacher.classHourStandards.map((standard, index) => {
                        // 根据课程类型设置不同的图标 - 更详细的匹配
                        let iconClass = "fas fa-chalkboard-teacher"; // 默认图标
                        
                        const type = standard.type;
                        const typeLower = type.toLowerCase();
                        
                        // 一对一(网课)
                        if ((typeLower.includes("一对一") || typeLower.includes("1对1")) && 
                            (typeLower.includes("网课") || typeLower.includes("在线") || 
                             typeLower.includes("online") || typeLower.includes("remote"))) {
                          iconClass = "fas fa-laptop-house"; // 一个人和电脑的图标
                        }
                        // 一对三
                        else if (typeLower.includes("一对三") || typeLower.includes("1对3") || 
                                 typeLower.includes("one-on-three") || typeLower.includes("one to three")) {
                          iconClass = "fas fa-users"; // 三个人的图标
                        }
                        // 一对二
                        else if (typeLower.includes("一对二") || typeLower.includes("1对2") || 
                                 typeLower.includes("one-on-two") || typeLower.includes("one to two")) {
                          iconClass = "fas fa-user-friends"; // 两个人的图标
                        }
                        // 一对一
                        else if (typeLower.includes("一对一") || typeLower.includes("1对1") || 
                                 typeLower.includes("one-on-one") || typeLower.includes("one to one") || 
                                 typeLower.includes("individual") || typeLower.includes("private")) {
                          iconClass = "fas fa-user"; // 一个人的图标
                        }
                        // 小组或班级相关课程
                        else if (typeLower.includes("小组") || typeLower.includes("团体") || typeLower.includes("班级") || 
                                typeLower.includes("group") || typeLower.includes("class") || typeLower.includes("team")) {
                          iconClass = "fas fa-users";
                        } 
                        // 在线相关课程
                        else if (typeLower.includes("在线") || typeLower.includes("远程") || typeLower.includes("网络") || 
                                typeLower.includes("online") || typeLower.includes("remote") || typeLower.includes("virtual")) {
                          iconClass = "fas fa-laptop";
                        }
                        // VIP课程
                        else if (typeLower.includes("vip") || typeLower.includes("高端") || typeLower.includes("premium")) {
                          iconClass = "fas fa-crown";
                        }
                        // 考试辅导课程
                        else if (typeLower.includes("考试") || typeLower.includes("辅导") || typeLower.includes("exam") || 
                                typeLower.includes("test") || typeLower.includes("preparation")) {
                          iconClass = "fas fa-book";
                        }
                        // 写作或口语课程
                        else if (typeLower.includes("写作") || typeLower.includes("写") || typeLower.includes("essay") || 
                                typeLower.includes("writing")) {
                          iconClass = "fas fa-pen";
                        }
                        else if (typeLower.includes("口语") || typeLower.includes("对话") || typeLower.includes("speaking") || 
                                typeLower.includes("conversation")) {
                          iconClass = "fas fa-comments";
                        }
                        // 阅读课程
                        else if (typeLower.includes("阅读") || typeLower.includes("读") || typeLower.includes("reading")) {
                          iconClass = "fas fa-book-open";
                        }
                        
                        return (
                          <div key={index} className="class-hour-card">
                            <div className="class-hour-icon">
                              <i className={iconClass}></i>
                            </div>
                            <div className="class-hour-title">{standard.type}</div>
                            <div className="class-hour-price">{standard.standard}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                
                {/* 9. 教学成果 */}
                {selectedTeacher.teachingResults && selectedTeacher.teachingResults.length > 0 && (
                  <div className="detail-section teaching-results">
                    <h3>{isEnglish ? 'Teaching Results' : '教学成果'}</h3>
                    <div className="teaching-results-list">
                      {selectedTeacher.teachingResults.map((result, index) => (
                        <div key={index} className="teaching-result-item">
                          <p><strong>{isEnglish ? 'Student' : '学生'}: {result.studentName}</strong></p>
                          <p>{isEnglish ? 'Started Learning' : '开始学习时间'}: {moment(result.startLearningDate).format('YYYY.MM')}</p>
                          <p>{isEnglish ? 'Achievement Date' : '成果日期'}: {moment(result.achievementDate).format('YYYY.MM')}</p>
                          {result.achievementDetails && <p>{result.achievementDetails}</p>}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </Modal>
      
      {/* 二维码弹窗 */}
      <Modal
        title={isEnglish ? 'Scan QR Code to Chat' : '扫码私聊老师'}
        open={qrCodeVisible}
        onCancel={handleCloseQRCode}
        footer={[
          <Button key="close" onClick={handleCloseQRCode}>
            {isEnglish ? 'Close' : '关闭'}
          </Button>
        ]}
        width={350}
        className="qrcode-modal"
      >
        <div className="qrcode-container">
          <img 
            src="images/QRcode.jpg" 
            alt={isEnglish ? 'QR Code' : '二维码'} 
            className="qrcode-image" 
          />
          <p className="qrcode-tip">
            {isEnglish ? 'Scan the QR code to chat with the teacher' : '扫描二维码与老师私聊'}
          </p>
        </div>
      </Modal>
      
      {/* 图片预览模态框 */}
      <Modal
        title={previewImageTitle}
        open={previewImageVisible}
        onCancel={handleCloseImagePreview}
        footer={[
          <Button key="close" onClick={handleCloseImagePreview}>
            {isEnglish ? 'Close' : '关闭'}
          </Button>
        ]}
        width={800}
        className="image-preview-modal"
      >
        <div className="image-preview-container">
          <img 
            src={previewImageUrl} 
            alt={previewImageTitle} 
            className="image-preview"
            style={{ maxWidth: '100%', maxHeight: '70vh' }}
          />
        </div>
      </Modal>
      
      {/* 欢迎弹窗 */}
      <Modal
        title={isEnglish ? 'Welcome to Teacher Chat' : '欢迎来到Teacher私下聊'}
        open={welcomeModalVisible}
        onCancel={handleCloseWelcomeModal}
        footer={[
          <Button key="close" type="primary" onClick={handleCloseWelcomeModal}>
            {isEnglish ? 'I Got It' : '我知道了'}
          </Button>
        ]}
        className="welcome-modal"
        centered
      >
        <div className="welcome-icon">
          <i className="fas fa-hands-helping"></i>
        </div>
        <p className="welcome-message">
          {isEnglish 
            ? 'Click on the teacher card to view detailed teacher information and chat privately with the teacher. Come join us!' 
            : '点击教师卡片查看教师详细信息和私聊老师喔，快来加入我们吧！'}
        </p>
      </Modal>
    </div>
  );
};

export default HomePage; 
import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Table, Space, Modal, Form, Input, message, Typography, Popconfirm } from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../services/useAuth';
import { useTeachers } from '../services/useTeachers';
import './AdminDashboard.css';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const AdminDashboard: React.FC = () => {
  const { logout, username, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { teachers, loading, fetchTeachers, createTeacher, deleteTeacher } = useTeachers();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loadingTeachers, setLoadingTeachers] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const pageSize = 10;

  // 处理侧边栏折叠
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // 响应窗口大小变化
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      }
    };

    // 初始检查
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 给状态一点时间稳定
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialized(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);

  // 安全地加载教师列表
  const safelyFetchTeachers = async () => {
    try {
      console.log('开始加载教师列表...');
      setLoadingTeachers(true);
      setLoadError(null);
      await fetchTeachers();
      console.log('教师列表加载完成');
    } catch (error: any) {
      console.error('加载教师列表出错:', error);
      setLoadError(error?.message || '加载数据失败，请刷新页面重试');
    } finally {
      setLoadingTeachers(false);
    }
  };

  // 只在组件初始化且登录状态稳定后加载数据
  useEffect(() => {
    if (!initialized) return;
    
    if (isLoggedIn) {
      console.log('AdminDashboard: 用户已登录，正在加载教师列表');
      safelyFetchTeachers();
    }
  }, [isLoggedIn, initialized]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // 这些点击事件处理会将用户导航到子路由
  const handleViewBasicInfo = (teacherId: number) => {
    console.log(`AdminDashboard: 导航到教师 ${teacherId} 的基本信息`);
    // 使用replace: false确保不会替换历史记录，方便用户返回
    navigate(`/admin/teachers/${teacherId}/basic-info`, { replace: false });
  };

  const handleViewDetailedInfo = (teacherId: number) => {
    console.log(`AdminDashboard: 导航到教师 ${teacherId} 的详细信息`);
    // 使用replace: false确保不会替换历史记录，方便用户返回
    navigate(`/admin/teachers/${teacherId}/detailed-info`, { replace: false });
  };

  // 如果用户在加载过程中被登出，不立即重定向
  // 交给ProtectedRoute处理重定向，避免竞争条件
  if (initialized && !isLoggedIn) {
    console.log('AdminDashboard: 用户未登录，但不直接重定向');
    return null;
  }

  const handleCreateTeacher = async (values: any) => {
    const success = await createTeacher(values);
    if (success) {
      setIsCreateModalVisible(false);
      form.resetFields();
    }
  };

  const handleDeleteTeacher = async (id: number) => {
    const success = await deleteTeacher(id);
    if (success) {
      message.success('教师删除成功');
      
      // 计算当前页面应该显示的教师数
      const totalItems = teachers.length - 1; // 减去被删除的一个
      const totalPages = Math.ceil(totalItems / pageSize);
      
      // 如果当前页已经没有记录，且不是第一页，则切换到上一页
      if (currentPage > totalPages && currentPage > 1) {
        setCurrentPage(totalPages);
      }
    }
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      width: 80,
      className: 'serial-number-cell',
      render: (_: any, __: any, index: number) => (currentPage - 1) * pageSize + index + 1
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: '状态',
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean) => (
        <span className={active ? "status-active" : "status-inactive"}>
          {active ? '活跃' : '禁用'}
        </span>
      )
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button 
            type="primary"
            icon={<InfoCircleOutlined />}
            onClick={() => handleViewBasicInfo(record.id)}
          >
            基本信息
          </Button>
          <Button 
            icon={<EditOutlined />}
            onClick={() => handleViewDetailedInfo(record.id)}
          >
            详细信息
          </Button>
          <Popconfirm
            title="确定要删除此教师吗？"
            onConfirm={() => handleDeleteTeacher(record.id)}
            okText="确定"
            cancelText="取消"
          >
            <Button 
              danger 
              icon={<DeleteOutlined />}
            >
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Layout className="layout-container">
      <Header className="header-container">
        <div className="header-title">
          <Button 
            type="text" 
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} 
            onClick={toggleSidebar}
            style={{ color: 'white', marginRight: '10px', padding: '4px' }}
          />
          DMTeachers 管理系统
        </div>
        <div className="header-user-info">
          <Space>
            <UserOutlined /> {username}
            <Button 
              type="link" 
              icon={<LogoutOutlined />} 
              className="logout-button" 
              onClick={handleLogout}
            >
              退出登录
            </Button>
          </Space>
        </div>
      </Header>
      <Layout>
        <Sider 
          width={200} 
          style={{ background: '#fff' }} 
          collapsible 
          collapsed={collapsed}
          collapsedWidth={0}
          breakpoint="md"
          onBreakpoint={(broken) => {
            if (broken) {
              setCollapsed(true);
            }
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            className="sider-menu"
          >
            <Menu.Item key="1" icon={<UserOutlined />}>
              教师管理
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="content-layout">
          <Content className="content-box">
            <div className="content-container">
              <Title level={4}>教师管理</Title>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => setIsCreateModalVisible(true)}
                loading={loadingTeachers}
                disabled={loadingTeachers}
              >
                添加教师
              </Button>
            </div>

            {loadError ? (
              <div className="error-message">
                <p>加载出错: {loadError}</p>
                <Button type="primary" onClick={safelyFetchTeachers}>重试</Button>
              </div>
            ) : (
              <div className="responsive-table-container">
                <Table
                  className="teachers-table"
                  dataSource={teachers}
                  columns={columns}
                  rowKey="id"
                  loading={loadingTeachers}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (page) => setCurrentPage(page),
                    showSizeChanger: false
                  }}
                  scroll={{ x: 'max-content' }}
                />
              </div>
            )}

            <Modal
              title="创建教师"
              visible={isCreateModalVisible}
              onCancel={() => setIsCreateModalVisible(false)}
              footer={null}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleCreateTeacher}
              >
                <Form.Item
                  name="name"
                  label="姓名"
                  rules={[{ required: true, message: '请输入教师姓名' }]}
                >
                  <Input placeholder="请输入教师姓名" />
                </Form.Item>

                <Form.Item
                  name="username"
                  label="用户名"
                  rules={[{ required: true, message: '请输入用户名' }]}
                >
                  <Input placeholder="请输入用户名" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="密码"
                  rules={[{ required: true, message: '请输入密码' }]}
                >
                  <Input.Password placeholder="请输入密码" />
                </Form.Item>

                <Form.Item>
                  <Space className="form-button-group">
                    <Button onClick={() => setIsCreateModalVisible(false)}>
                      取消
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      创建
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
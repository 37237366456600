import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Button, Tabs, Typography, Spin, Alert, Card, Form, 
  Input, DatePicker, Select, Upload, Space, Table,
  Popconfirm, Divider, message, InputNumber, Checkbox
} from 'antd';
import { 
  ArrowLeftOutlined, UploadOutlined, PlusOutlined,
  EditOutlined, DeleteOutlined, LoadingOutlined 
} from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { useTeachers } from '../services/useTeachers';
import { fileAPI, teacherAPI } from '../services/api';
import { EducationInfo, LanguageAbilityInfo, CertificateInfo, TeachingExpInfo, TeachingContentInfo, ClassHourStandardInfo, TeachingResultInfo } from '../types';
import '../styles.css';
import axios from 'axios';
import './TeacherDetailedInfo.css';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const TeacherDetailedInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const teacherId = Number(id);
  
  const { selectedTeacher, loading, error, fetchTeacherDetails } = useTeachers();
  const [activeTab, setActiveTab] = useState('1');
  
  // 教育背景数据
  const [educations, setEducations] = useState<EducationInfo[]>([]);
  const [educationForm] = Form.useForm();
  const [editingEducation, setEditingEducation] = useState<EducationInfo | null>(null);
  const [educationsLoading, setEducationsLoading] = useState(false);
  const [uploadingEducationProof, setUploadingEducationProof] = useState(false);
  const [educationProofUrl, setEducationProofUrl] = useState('');
  
  // 语言能力数据
  const [languageAbility, setLanguageAbility] = useState<LanguageAbilityInfo | null>(null);
  const [languageForm] = Form.useForm();
  const [languageLoading, setLanguageLoading] = useState(false);
  const [savingLanguage, setSavingLanguage] = useState(false);
  
  // 专业证书数据
  const [certificates, setCertificates] = useState<CertificateInfo[]>([]);
  const [certificateForm] = Form.useForm();
  const [editingCertificate, setEditingCertificate] = useState<CertificateInfo | null>(null);
  const [certificatesLoading, setCertificatesLoading] = useState(false);
  const [uploadingCertificate, setUploadingCertificate] = useState(false);
  const [certificateImageUrl, setCertificateImageUrl] = useState('');
  
  // 教学经历数据
  const [teachingExps, setTeachingExps] = useState<TeachingExpInfo[]>([]);
  const [teachingExpForm] = Form.useForm();
  const [editingTeachingExp, setEditingTeachingExp] = useState<TeachingExpInfo | null>(null);
  const [teachingExpsLoading, setTeachingExpsLoading] = useState(false);
  
  // 教学内容数据
  const [teachingContents, setTeachingContents] = useState<TeachingContentInfo[]>([]);
  const [teachingContentForm] = Form.useForm();
  const [editingTeachingContent, setEditingTeachingContent] = useState<TeachingContentInfo | null>(null);
  const [teachingContentsLoading, setTeachingContentsLoading] = useState(false);
  
  // 课时标准数据
  const [classHourStandards, setClassHourStandards] = useState<ClassHourStandardInfo[]>([]);
  const [classHourStandardForm] = Form.useForm();
  const [editingClassHourStandard, setEditingClassHourStandard] = useState<ClassHourStandardInfo | null>(null);
  const [classHourStandardsLoading, setClassHourStandardsLoading] = useState(false);
  
  // 教学成果数据
  const [teachingResults, setTeachingResults] = useState<TeachingResultInfo[]>([]);
  const [teachingResultForm] = Form.useForm();
  const [editingTeachingResult, setEditingTeachingResult] = useState<TeachingResultInfo | null>(null);
  const [teachingResultsLoading, setTeachingResultsLoading] = useState(false);
  
  // 获取教师信息
  useEffect(() => {
    if (teacherId) {
      fetchTeacherDetails(teacherId);
    }
  }, [teacherId, fetchTeacherDetails]);
  
  // 获取教育背景数据
  const fetchEducations = async () => {
    try {
      setEducationsLoading(true);
      const response = await teacherAPI.getEducationInfoList(teacherId);
      if (response.data) {
        console.log('获取到的教育背景数据:', response.data);
        
        // 检查数据中是否包含id字段
        const hasInvalidData = response.data.some((item: any) => !item.id);
        if (hasInvalidData) {
          console.warn('教育背景数据中存在没有ID的记录');
        }
        
        setEducations(response.data);
      }
    } catch (error) {
      console.error('获取教育背景数据失败', error);
      message.error('获取教育背景数据失败');
    } finally {
      setEducationsLoading(false);
    }
  };
  
  // 获取语言能力数据
  const fetchLanguageAbility = async () => {
    try {
      setLanguageLoading(true);
      const response = await teacherAPI.getLanguageAbilityInfo(teacherId);
      if (response.data) {
        setLanguageAbility(response.data);
        languageForm.setFieldsValue({
          englishLevel: response.data.englishLevel,
          standardizedScores: response.data.standardizedScores,
          otherLanguages: response.data.otherLanguages
        });
      }
    } catch (error) {
      console.error('获取语言能力数据失败', error);
      // 如果是404，说明没有数据，这是正常的
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        message.error('获取语言能力数据失败');
      }
    } finally {
      setLanguageLoading(false);
    }
  };
  
  // 获取专业证书数据
  const fetchCertificates = async () => {
    try {
      setCertificatesLoading(true);
      const response = await teacherAPI.getCertificateInfoList(teacherId);
      if (response.data) {
        setCertificates(response.data);
      }
    } catch (error) {
      console.error('获取专业证书数据失败', error);
      message.error('获取专业证书数据失败');
    } finally {
      setCertificatesLoading(false);
    }
  };
  
  // 获取教学经历数据
  const fetchTeachingExps = async () => {
    try {
      setTeachingExpsLoading(true);
      const response = await teacherAPI.getTeachingExpInfoList(teacherId);
      if (response.data) {
        setTeachingExps(response.data);
      }
    } catch (error) {
      console.error('获取教学经历数据失败', error);
      message.error('获取教学经历数据失败');
    } finally {
      setTeachingExpsLoading(false);
    }
  };
  
  // 获取教学内容数据
  const fetchTeachingContents = async () => {
    try {
      setTeachingContentsLoading(true);
      const response = await teacherAPI.getTeachingContentInfoList(teacherId);
      if (response.data) {
        setTeachingContents(response.data);
      }
    } catch (error) {
      console.error('获取教学内容数据失败', error);
      message.error('获取教学内容数据失败');
    } finally {
      setTeachingContentsLoading(false);
    }
  };
  
  // 获取课时标准数据
  const fetchClassHourStandards = async () => {
    try {
      setClassHourStandardsLoading(true);
      const response = await teacherAPI.getClassHourStandardInfoList(teacherId);
      if (response.data) {
        setClassHourStandards(response.data);
      }
    } catch (error) {
      console.error('获取课时标准数据失败', error);
      message.error('获取课时标准数据失败');
    } finally {
      setClassHourStandardsLoading(false);
    }
  };
  
  // 获取教学成果数据
  const fetchTeachingResults = async () => {
    try {
      setTeachingResultsLoading(true);
      const response = await teacherAPI.getTeachingResultInfoList(teacherId);
      if (response.data) {
        setTeachingResults(response.data);
      }
    } catch (error) {
      console.error('获取教学成果数据失败', error);
      message.error('获取教学成果数据失败');
    } finally {
      setTeachingResultsLoading(false);
    }
  };
  
  // 当选择标签页时加载相应数据
  useEffect(() => {
    if (teacherId) {
      if (activeTab === '1') {
        fetchEducations();
      } else if (activeTab === '2') {
        fetchLanguageAbility();
      } else if (activeTab === '3') {
        fetchCertificates();
      } else if (activeTab === '4') {
        fetchTeachingExps();
      } else if (activeTab === '5') {
        fetchTeachingContents();
      } else if (activeTab === '6') {
        fetchClassHourStandards();
      } else if (activeTab === '7') {
        fetchTeachingResults();
      }
    }
  }, [activeTab, teacherId]);
  
  // 处理返回按钮
  const handleBack = () => {
    navigate('/admin/dashboard');
  };
  
  // 处理标签页切换
  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };
  
  // 处理教育背景证明图片上传
  const handleEducationProofUpload = async (file: RcFile) => {
    try {
      setUploadingEducationProof(true);
      
      const response = await fileAPI.uploadFile(file);
      if (response.data && response.data.url) {
        setEducationProofUrl(response.data.url);
        message.success('教育背景证明上传成功');
      } else {
        message.error('教育背景证明上传失败');
      }
    } catch (error) {
      console.error('上传教育背景证明出错', error);
      message.error('教育背景证明上传失败，请重试');
    } finally {
      setUploadingEducationProof(false);
    }
    
    return false; // 阻止默认上传行为
  };
  
  // 处理专业证书图片上传
  const handleCertificateImageUpload = async (file: RcFile) => {
    try {
      setUploadingCertificate(true);
      
      const response = await fileAPI.uploadFile(file);
      if (response.data && response.data.url) {
        setCertificateImageUrl(response.data.url);
        message.success('专业证书图片上传成功');
      } else {
        message.error('专业证书图片上传失败');
      }
    } catch (error) {
      console.error('上传专业证书图片出错', error);
      message.error('专业证书图片上传失败，请重试');
    } finally {
      setUploadingCertificate(false);
    }
    
    return false; // 阻止默认上传行为
  };
  
  // 处理添加或更新教育背景
  const handleEducationSubmit = async (values: any) => {
    try {
      // 准备数据
      const educationData: Partial<EducationInfo> = {
        schoolName: values.schoolName,
        startDate: values.startDate.format('YYYY-MM-01'),
        endDate: values.endDate.format('YYYY-MM-01'),
        majorName: values.majorName,
        educationType: values.educationType,
        description: values.description,
        proofImage: educationProofUrl
      };
      
      if (editingEducation) {
        // 更新现有教育背景
        await teacherAPI.updateEducationInfo(editingEducation.id!, educationData);
        message.success('教育背景更新成功');
      } else {
        // 添加新教育背景
        await teacherAPI.addEducationInfo(teacherId, educationData);
        message.success('教育背景添加成功');
      }
      
      // 重置表单和状态
      educationForm.resetFields();
      setEditingEducation(null);
      setEducationProofUrl('');
      
      // 重新获取教育背景列表
      fetchEducations();
    } catch (error: any) {
      console.error('保存教育背景出错', error);
      
      let errorMessage = '保存失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `保存失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `保存失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 处理语言能力提交
  const handleLanguageSubmit = async (values: any) => {
    try {
      setSavingLanguage(true);
      
      const languageData: Partial<LanguageAbilityInfo> = {
        englishLevel: values.englishLevel,
        standardizedScores: values.standardizedScores,
        otherLanguages: values.otherLanguages
      };
      
      await teacherAPI.updateLanguageAbilityInfo(teacherId, languageData);
      message.success('语言能力保存成功');
      
      // 刷新数据
      fetchLanguageAbility();
    } catch (error) {
      console.error('保存语言能力出错', error);
      message.error('保存失败，请重试');
    } finally {
      setSavingLanguage(false);
    }
  };
  
  // 处理添加或更新专业证书
  const handleCertificateSubmit = async (values: any) => {
    try {
      // 准备数据
      const certificateData: Partial<CertificateInfo> = {
        obtainYear: values.obtainYear,
        certificateName: values.certificateName,
        issuingAuthority: values.issuingAuthority,
        certificateImage: certificateImageUrl
      };
      
      if (editingCertificate) {
        // 更新现有专业证书
        await teacherAPI.updateCertificateInfo(editingCertificate.id!, certificateData);
        message.success('专业证书更新成功');
      } else {
        // 添加新专业证书
        await teacherAPI.addCertificateInfo(teacherId, certificateData);
        message.success('专业证书添加成功');
      }
      
      // 重置表单和状态
      certificateForm.resetFields();
      setEditingCertificate(null);
      setCertificateImageUrl('');
      
      // 重新获取专业证书列表
      fetchCertificates();
    } catch (error: any) {
      console.error('保存专业证书出错', error);
      
      let errorMessage = '保存失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `保存失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `保存失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 编辑教育背景
  const handleEditEducation = (record: EducationInfo) => {
    setEditingEducation(record);
    setEducationProofUrl(record.proofImage || '');
    
    educationForm.setFieldsValue({
      schoolName: record.schoolName,
      startDate: moment(record.startDate),
      endDate: moment(record.endDate),
      majorName: record.majorName,
      educationType: record.educationType,
      description: record.description
    });
  };
  
  // 编辑专业证书
  const handleEditCertificate = (record: CertificateInfo) => {
    setEditingCertificate(record);
    setCertificateImageUrl(record.certificateImage || '');
    
    certificateForm.setFieldsValue({
      obtainYear: record.obtainYear,
      certificateName: record.certificateName,
      issuingAuthority: record.issuingAuthority
    });
  };
  
  // 删除教育背景
  const handleDeleteEducation = async (educationId?: number) => {
    if (!educationId) {
      console.error('删除失败：教育背景ID无效', educationId);
      message.error('删除失败：无效的教育背景ID');
      return;
    }
    
    try {
      console.log('正在删除教育背景，ID:', educationId);
      await teacherAPI.deleteEducationInfo(educationId);
      message.success('教育背景删除成功');
      fetchEducations();
    } catch (error: any) {
      console.error('删除教育背景出错', error);
      
      let errorMessage = '删除失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `删除失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `删除失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 删除专业证书
  const handleDeleteCertificate = async (certificateId?: number) => {
    if (!certificateId) {
      console.error('删除失败：专业证书ID无效', certificateId);
      message.error('删除失败：无效的专业证书ID');
      return;
    }
    
    try {
      console.log('正在删除专业证书，ID:', certificateId);
      await teacherAPI.deleteCertificateInfo(certificateId);
      message.success('专业证书删除成功');
      fetchCertificates();
    } catch (error: any) {
      console.error('删除专业证书出错', error);
      
      let errorMessage = '删除失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `删除失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `删除失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 重置教育背景表单
  const resetEducationForm = () => {
    educationForm.resetFields();
    setEditingEducation(null);
    setEducationProofUrl('');
  };
  
  // 重置专业证书表单
  const resetCertificateForm = () => {
    certificateForm.resetFields();
    setEditingCertificate(null);
    setCertificateImageUrl('');
  };
  
  // 处理添加或更新教学经历
  const handleTeachingExpSubmit = async (values: any) => {
    try {
      // 准备数据
      const teachingExpData: Partial<TeachingExpInfo> = {
        startDate: values.startDate.format('YYYY-MM-01'),
        endDate: values.isCurrent ? undefined : values.endDate?.format('YYYY-MM-01'),
        institutionName: values.institutionName,
        position: values.position,
        jobDescription: values.jobDescription,
        isCurrent: values.isCurrent
      };
      
      if (editingTeachingExp) {
        // 更新现有教学经历
        await teacherAPI.updateTeachingExpInfo(editingTeachingExp.id!, teachingExpData);
        message.success('教学经历更新成功');
      } else {
        // 添加新教学经历
        await teacherAPI.addTeachingExpInfo(teacherId, teachingExpData);
        message.success('教学经历添加成功');
      }
      
      // 重置表单和状态
      teachingExpForm.resetFields();
      setEditingTeachingExp(null);
      
      // 重新获取教学经历列表
      fetchTeachingExps();
    } catch (error) {
      console.error('保存教学经历出错', error);
      message.error('保存失败，请重试');
    }
  };
  
  // 编辑教学经历
  const handleEditTeachingExp = (record: TeachingExpInfo) => {
    setEditingTeachingExp(record);
    
    teachingExpForm.setFieldsValue({
      institutionName: record.institutionName,
      startDate: moment(record.startDate),
      endDate: record.endDate ? moment(record.endDate) : undefined,
      position: record.position,
      jobDescription: record.jobDescription,
      isCurrent: record.isCurrent
    });
  };
  
  // 删除教学经历
  const handleDeleteTeachingExp = async (expId?: number) => {
    if (!expId) {
      console.error('删除失败：教学经历ID无效', expId);
      message.error('删除失败：无效的教学经历ID');
      return;
    }
    
    try {
      console.log('正在删除教学经历，ID:', expId);
      await teacherAPI.deleteTeachingExpInfo(expId);
      message.success('教学经历删除成功');
      fetchTeachingExps();
    } catch (error: any) {
      console.error('删除教学经历出错', error);
      
      let errorMessage = '删除失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `删除失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `删除失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 重置教学经历表单
  const resetTeachingExpForm = () => {
    teachingExpForm.resetFields();
    setEditingTeachingExp(null);
  };
  
  // 处理添加或更新教学内容
  const handleTeachingContentSubmit = async (values: any) => {
    try {
      // 准备数据
      const teachingContentData: Partial<TeachingContentInfo> = {
        subject: values.subject,
        description: values.description
      };
      
      if (editingTeachingContent) {
        // 更新现有教学内容
        await teacherAPI.updateTeachingContentInfo(editingTeachingContent.id!, teachingContentData);
        message.success('教学内容更新成功');
      } else {
        // 添加新教学内容
        await teacherAPI.addTeachingContentInfo(teacherId, teachingContentData);
        message.success('教学内容添加成功');
      }
      
      // 重置表单和状态
      teachingContentForm.resetFields();
      setEditingTeachingContent(null);
      
      // 重新获取教学内容列表
      fetchTeachingContents();
    } catch (error) {
      console.error('保存教学内容出错', error);
      message.error('保存失败，请重试');
    }
  };
  
  // 编辑教学内容
  const handleEditTeachingContent = (record: TeachingContentInfo) => {
    setEditingTeachingContent(record);
    
    teachingContentForm.setFieldsValue({
      subject: record.subject,
      description: record.description
    });
  };
  
  // 删除教学内容
  const handleDeleteTeachingContent = async (contentId: number) => {
    try {
      await teacherAPI.deleteTeachingContentInfo(contentId);
      message.success('教学内容删除成功');
      fetchTeachingContents();
    } catch (error: any) {
      console.error('删除教学内容出错', error);
      
      let errorMessage = '删除失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `删除失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `删除失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 重置教学内容表单
  const resetTeachingContentForm = () => {
    teachingContentForm.resetFields();
    setEditingTeachingContent(null);
  };
  
  // 处理添加或更新课时标准
  const handleClassHourStandardSubmit = async (values: any) => {
    try {
      // 准备数据
      const classHourStandardData: Partial<ClassHourStandardInfo> = {
        type: values.type,
        standard: values.standard
      };
      
      if (editingClassHourStandard) {
        // 更新现有课时标准
        await teacherAPI.updateClassHourStandardInfo(editingClassHourStandard.id!, classHourStandardData);
        message.success('课时标准更新成功');
      } else {
        // 添加新课时标准
        await teacherAPI.addClassHourStandardInfo(teacherId, classHourStandardData);
        message.success('课时标准添加成功');
      }
      
      // 重置表单和状态
      classHourStandardForm.resetFields();
      setEditingClassHourStandard(null);
      
      // 重新获取课时标准列表
      fetchClassHourStandards();
    } catch (error) {
      console.error('保存课时标准出错', error);
      message.error('保存失败，请重试');
    }
  };
  
  // 编辑课时标准
  const handleEditClassHourStandard = (record: ClassHourStandardInfo) => {
    setEditingClassHourStandard(record);
    
    classHourStandardForm.setFieldsValue({
      type: record.type,
      standard: record.standard
    });
  };
  
  // 删除课时标准
  const handleDeleteClassHourStandard = async (standardId: number) => {
    try {
      await teacherAPI.deleteClassHourStandardInfo(standardId);
      message.success('课时标准删除成功');
      fetchClassHourStandards();
    } catch (error: any) {
      console.error('删除课时标准出错', error);
      
      let errorMessage = '删除失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `删除失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `删除失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 重置课时标准表单
  const resetClassHourStandardForm = () => {
    classHourStandardForm.resetFields();
    setEditingClassHourStandard(null);
  };
  
  // 处理添加或更新教学成果
  const handleTeachingResultSubmit = async (values: any) => {
    try {
      // 准备数据
      const teachingResultData: Partial<TeachingResultInfo> = {
        studentName: values.studentName,
        startLearningDate: values.startLearningDate.format('YYYY-MM-01'),
        achievementDate: values.achievementDate.format('YYYY-MM-01'),
        achievementDetails: values.achievementDetails
      };
      
      if (editingTeachingResult) {
        // 更新现有教学成果
        await teacherAPI.updateTeachingResultInfo(editingTeachingResult.id!, teachingResultData);
        message.success('教学成果更新成功');
      } else {
        // 添加新教学成果
        await teacherAPI.addTeachingResultInfo(teacherId, teachingResultData);
        message.success('教学成果添加成功');
      }
      
      // 重置表单和状态
      teachingResultForm.resetFields();
      setEditingTeachingResult(null);
      
      // 重新获取教学成果列表
      fetchTeachingResults();
    } catch (error) {
      console.error('保存教学成果出错', error);
      message.error('保存失败，请重试');
    }
  };
  
  // 编辑教学成果
  const handleEditTeachingResult = (record: TeachingResultInfo) => {
    setEditingTeachingResult(record);
    
    teachingResultForm.setFieldsValue({
      studentName: record.studentName,
      startLearningDate: moment(record.startLearningDate),
      achievementDate: moment(record.achievementDate),
      achievementDetails: record.achievementDetails
    });
  };
  
  // 删除教学成果
  const handleDeleteTeachingResult = async (resultId: number) => {
    try {
      await teacherAPI.deleteTeachingResultInfo(resultId);
      message.success('教学成果删除成功');
      fetchTeachingResults();
    } catch (error: any) {
      console.error('删除教学成果出错', error);
      
      let errorMessage = '删除失败，请重试';
      
      // 尝试获取更详细的错误信息
      if (error.response?.data?.message) {
        errorMessage = `删除失败: ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `删除失败: ${error.message}`;
      }
      
      message.error(errorMessage);
    }
  };
  
  // 重置教学成果表单
  const resetTeachingResultForm = () => {
    teachingResultForm.resetFields();
    setEditingTeachingResult(null);
  };
  
  // 教育背景表格列定义
  const educationColumns = [
    {
      title: '学校名称',
      dataIndex: 'schoolName',
      key: 'schoolName',
    },
    {
      title: '入学年月',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: string) => moment(date).format('YYYY-MM')
    },
    {
      title: '毕业年月',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: string) => moment(date).format('YYYY-MM')
    },
    {
      title: '专业名称',
      dataIndex: 'majorName',
      key: 'majorName',
    },
    {
      title: '教育阶段',
      dataIndex: 'educationType',
      key: 'educationType',
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: EducationInfo) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            size="small" 
            onClick={() => handleEditEducation(record)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这条教育经历吗?"
            onConfirm={() => handleDeleteEducation(record.id)}
            okText="确定"
            cancelText="取消"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  // 专业证书表格列定义
  const certificateColumns = [
    {
      title: '获取年份',
      dataIndex: 'obtainYear',
      key: 'obtainYear',
    },
    {
      title: '证书名称',
      dataIndex: 'certificateName',
      key: 'certificateName',
    },
    {
      title: '颁发机构',
      dataIndex: 'issuingAuthority',
      key: 'issuingAuthority',
    },
    {
      title: '证书图片',
      dataIndex: 'certificateImage',
      key: 'certificateImage',
      render: (image: string) => image ? (
        <a href={image} target="_blank" rel="noopener noreferrer">查看图片</a>
      ) : '无'
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: CertificateInfo) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            size="small" 
            onClick={() => handleEditCertificate(record)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这个专业证书吗?"
            onConfirm={() => handleDeleteCertificate(record.id!)}
            okText="确定"
            cancelText="取消"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  // 教学经历表格列定义
  const teachingExpColumns = [
    {
      title: '开始时间',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: string) => moment(date).format('YYYY-MM')
    },
    {
      title: '结束时间',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: string, record: TeachingExpInfo) => 
        record.isCurrent ? '至今' : (date ? moment(date).format('YYYY-MM') : '未设置')
    },
    {
      title: '机构名称',
      dataIndex: 'institutionName',
      key: 'institutionName',
    },
    {
      title: '任职职位',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: TeachingExpInfo) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            size="small" 
            onClick={() => handleEditTeachingExp(record)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这条教学经历吗?"
            onConfirm={() => handleDeleteTeachingExp(record.id!)}
            okText="确定"
            cancelText="取消"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  // 教学内容表格列定义
  const teachingContentColumns = [
    {
      title: '教学科目',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: TeachingContentInfo) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            size="small" 
            onClick={() => handleEditTeachingContent(record)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这个教学内容吗?"
            onConfirm={() => handleDeleteTeachingContent(record.id!)}
            okText="确定"
            cancelText="取消"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  // 课时标准表格列定义
  const classHourStandardColumns = [
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '标准',
      dataIndex: 'standard',
      key: 'standard',
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: ClassHourStandardInfo) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            size="small" 
            onClick={() => handleEditClassHourStandard(record)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这个课时标准吗?"
            onConfirm={() => handleDeleteClassHourStandard(record.id!)}
            okText="确定"
            cancelText="取消"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  // 教学成果表格列定义
  const teachingResultColumns = [
    {
      title: '学员名称',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: '开始学习时间',
      dataIndex: 'startLearningDate',
      key: 'startLearningDate',
      render: (date: string) => moment(date).format('YYYY-MM')
    },
    {
      title: '成果获得时间',
      dataIndex: 'achievementDate',
      key: 'achievementDate',
      render: (date: string) => moment(date).format('YYYY-MM')
    },
    {
      title: '成果详情',
      dataIndex: 'achievementDetails',
      key: 'achievementDetails',
      ellipsis: true
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: TeachingResultInfo) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            size="small" 
            onClick={() => handleEditTeachingResult(record)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这个教学成果吗?"
            onConfirm={() => handleDeleteTeachingResult(record.id!)}
            okText="确定"
            cancelText="取消"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  // 渲染教育背景表单
  const renderEducationForm = () => (
    <Card title={editingEducation ? "编辑教育背景" : "添加教育背景"} 
          extra={editingEducation && 
            <Button onClick={resetEducationForm}>取消编辑</Button>
          }>
      <Form
        form={educationForm}
        layout="vertical"
        onFinish={handleEducationSubmit}
      >
        <Form.Item
          name="schoolName"
          label="学校名称"
          rules={[{ required: true, message: '请输入学校名称' }]}
        >
          <Input placeholder="请输入学校名称" />
        </Form.Item>
        
        <Form.Item
          name="startDate"
          label="入学年月"
          rules={[{ required: true, message: '请选择入学年月' }]}
        >
          <DatePicker picker="month" style={{ width: '100%' }} />
        </Form.Item>
        
        <Form.Item
          name="endDate"
          label="毕业年月"
          rules={[{ required: true, message: '请选择毕业年月' }]}
        >
          <DatePicker picker="month" style={{ width: '100%' }} />
        </Form.Item>
        
        <Form.Item
          name="majorName"
          label="专业名称"
          rules={[{ required: true, message: '请输入专业名称' }]}
        >
          <Input placeholder="请输入专业名称" />
        </Form.Item>
        
        <Form.Item
          label="教育阶段"
          name="educationType"
          rules={[{ required: true, message: '请输入教育阶段' }]}
        >
          <Input placeholder="请输入教育阶段" />
        </Form.Item>
        
        <Form.Item
          label="教育背景证明图片"
        >
          <Space direction="vertical" className="form-upload-container">
            {educationProofUrl && (
              <div className="image-preview">
                <img src={educationProofUrl} alt="教育背景证明" className="preview-image" />
              </div>
            )}
            
            <Upload
              name="proof"
              accept="image/*"
              showUploadList={false}
              beforeUpload={handleEducationProofUpload}
            >
              <Button 
                icon={uploadingEducationProof ? <LoadingOutlined /> : <UploadOutlined />}
                loading={uploadingEducationProof}
              >
                {educationProofUrl ? '更新证明图片' : '上传证明图片'}
              </Button>
            </Upload>
          </Space>
        </Form.Item>
        
        <Form.Item
          name="description"
          label="教育阶段描述"
        >
          <TextArea rows={4} placeholder="请输入教育阶段描述" />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingEducation ? '更新' : '添加'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
  
  // 渲染语言能力表单
  const renderLanguageForm = () => (
    <Card title="语言能力">
      <Spin spinning={languageLoading}>
        <Form
          form={languageForm}
          layout="vertical"
          onFinish={handleLanguageSubmit}
        >
          <Form.Item
            name="englishLevel"
            label="英语语言水平"
            rules={[{ required: true, message: '请输入英语语言水平' }]}
          >
            <Input placeholder="例如：高级、流利、母语等级等" />
          </Form.Item>
          
          <Form.Item
            name="standardizedScores"
            label="语言标化成绩"
          >
            <TextArea 
              rows={4} 
              placeholder="例如：托福110分、雅思7.5分等，可以换行编写多个标化成绩" 
            />
          </Form.Item>
          
          <Form.Item
            name="otherLanguages"
            label="其他语言"
          >
            <TextArea 
              rows={4} 
              placeholder="例如：法语中级、日语初级等" 
            />
          </Form.Item>
          
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit"
              loading={savingLanguage}
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
  
  // 渲染专业证书表单
  const renderCertificateForm = () => (
    <Card title={editingCertificate ? "编辑专业证书" : "添加专业证书"} 
          extra={editingCertificate && 
            <Button onClick={resetCertificateForm}>取消编辑</Button>
          }>
      <Form
        form={certificateForm}
        layout="vertical"
        onFinish={handleCertificateSubmit}
      >
        <Form.Item
          name="obtainYear"
          label="获取年份"
          rules={[{ required: true, message: '请选择获取年份' }]}
        >
          <InputNumber 
            style={{ width: '100%' }} 
            min={1900} 
            max={2100} 
            placeholder="请输入年份，如2020"
          />
        </Form.Item>
        
        <Form.Item
          name="certificateName"
          label="证书名称"
          rules={[{ required: true, message: '请输入证书名称' }]}
        >
          <Input placeholder="请输入证书名称" />
        </Form.Item>
        
        <Form.Item
          name="issuingAuthority"
          label="颁发机构"
        >
          <Input placeholder="请输入颁发机构" />
        </Form.Item>
        
        <Form.Item
          label="专业证书图片"
        >
          <Space direction="vertical" className="form-upload-container">
            {certificateImageUrl && (
              <div className="image-preview">
                <img src={certificateImageUrl} alt="专业证书" className="preview-image" />
              </div>
            )}
            
            <Upload
              name="certificate"
              accept="image/*"
              showUploadList={false}
              beforeUpload={handleCertificateImageUpload}
            >
              <Button 
                icon={uploadingCertificate ? <LoadingOutlined /> : <UploadOutlined />}
                loading={uploadingCertificate}
              >
                {certificateImageUrl ? '更新证书图片' : '上传证书图片'}
              </Button>
            </Upload>
          </Space>
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingCertificate ? '更新' : '添加'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
  
  // 渲染教学经历表单
  const renderTeachingExpForm = () => (
    <Card title={editingTeachingExp ? "编辑教学经历" : "添加教学经历"} 
          extra={editingTeachingExp && 
            <Button onClick={resetTeachingExpForm}>取消编辑</Button>
          }>
      <Form
        form={teachingExpForm}
        layout="vertical"
        onFinish={handleTeachingExpSubmit}
      >
        <Form.Item
          name="institutionName"
          label="机构名称"
          rules={[{ required: true, message: '请输入机构名称' }]}
        >
          <Input placeholder="请输入机构名称" />
        </Form.Item>
        
        <Form.Item
          name="position"
          label="任职职位"
          rules={[{ required: true, message: '请输入任职职位' }]}
        >
          <Input placeholder="请输入任职职位" />
        </Form.Item>
        
        <Form.Item
          name="startDate"
          label="开始时间"
          rules={[{ required: true, message: '请选择开始时间' }]}
        >
          <DatePicker picker="month" style={{ width: '100%' }} />
        </Form.Item>
        
        <Form.Item
          name="isCurrent"
          valuePropName="checked"
        >
          <Checkbox>至今</Checkbox>
        </Form.Item>
        
        <Form.Item
          name="endDate"
          label="结束时间"
          dependencies={['isCurrent']}
          rules={[
            ({ getFieldValue }) => ({
              required: !getFieldValue('isCurrent'),
              message: '请选择结束时间或勾选"至今"'
            })
          ]}
        >
          <DatePicker 
            picker="month" 
            style={{ width: '100%' }} 
            disabled={teachingExpForm.getFieldValue('isCurrent')}
          />
        </Form.Item>
        
        <Form.Item
          name="jobDescription"
          label="工作描述"
        >
          <TextArea rows={4} placeholder="请输入工作描述" />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingTeachingExp ? '更新' : '添加'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
  
  // 渲染教学内容表单
  const renderTeachingContentForm = () => (
    <Card title={editingTeachingContent ? "编辑教学内容" : "添加教学内容"} 
          extra={editingTeachingContent && 
            <Button onClick={resetTeachingContentForm}>取消编辑</Button>
          }>
      <Form
        form={teachingContentForm}
        layout="vertical"
        onFinish={handleTeachingContentSubmit}
      >
        <Form.Item
          name="subject"
          label="教学科目"
          rules={[{ required: true, message: '请输入教学科目' }]}
        >
          <Input placeholder="请输入教学科目" />
        </Form.Item>
        
        <Form.Item
          name="description"
          label="描述"
        >
          <TextArea rows={4} placeholder="请输入教学内容描述" />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingTeachingContent ? '更新' : '添加'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
  
  // 渲染课时标准表单
  const renderClassHourStandardForm = () => (
    <Card title={editingClassHourStandard ? "编辑课时标准" : "添加课时标准"} 
          extra={editingClassHourStandard && 
            <Button onClick={resetClassHourStandardForm}>取消编辑</Button>
          }>
      <Form
        form={classHourStandardForm}
        layout="vertical"
        onFinish={handleClassHourStandardSubmit}
      >
        <Form.Item
          name="type"
          label="类型"
          rules={[{ required: true, message: '请输入课时标准类型' }]}
        >
          <Input placeholder="请输入课时标准类型，例如：一对一、小班课" />
        </Form.Item>
        
        <Form.Item
          name="standard"
          label="标准"
          rules={[{ required: true, message: '请输入标准' }]}
        >
          <Input placeholder="请输入课时标准，例如：200元/小时" />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingClassHourStandard ? '更新' : '添加'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
  
  // 渲染教学成果表单
  const renderTeachingResultForm = () => (
    <Card title={editingTeachingResult ? "编辑教学成果" : "添加教学成果"} 
          extra={editingTeachingResult && 
            <Button onClick={resetTeachingResultForm}>取消编辑</Button>
          }>
      <Form
        form={teachingResultForm}
        layout="vertical"
        onFinish={handleTeachingResultSubmit}
      >
        <Form.Item
          name="studentName"
          label="学员名称"
          rules={[{ required: true, message: '请输入学员名称' }]}
        >
          <Input placeholder="请输入学员姓名" />
        </Form.Item>
        
        <Form.Item
          name="startLearningDate"
          label="开始学习时间"
          rules={[{ required: true, message: '请选择开始学习时间' }]}
        >
          <DatePicker picker="month" style={{ width: '100%' }} />
        </Form.Item>
        
        <Form.Item
          name="achievementDate"
          label="成果获得时间"
          rules={[{ required: true, message: '请选择成果获得时间' }]}
        >
          <DatePicker picker="month" style={{ width: '100%' }} />
        </Form.Item>
        
        <Form.Item
          name="achievementDetails"
          label="成果详情"
        >
          <TextArea rows={4} placeholder="请输入成果详情描述" />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingTeachingResult ? '更新' : '添加'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
  
  // 基本加载状态和错误处理
  if (loading && !selectedTeacher) {
    return (
      <div className="loading-container">
        <Spin size="large" />
        <p>加载中...</p>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="error-container">
        <Alert type="error" message="加载失败" description={error} />
        <Button onClick={handleBack} icon={<ArrowLeftOutlined />} style={{ marginTop: 16 }}>
          返回
        </Button>
      </div>
    );
  }
  
  if (!selectedTeacher) {
    return (
      <div className="error-container">
        <Alert type="warning" message="教师不存在" description="未找到该教师信息，请返回重试" />
        <Button onClick={handleBack} icon={<ArrowLeftOutlined />} style={{ marginTop: 16 }}>
          返回
        </Button>
      </div>
    );
  }
  
  return (
    <div className="teacher-detailed-info-container">
      <div className="page-header">
        <Button 
          icon={<ArrowLeftOutlined />} 
          onClick={handleBack}
          style={{ marginRight: 16 }}
        >
          返回
        </Button>
        <Title level={4}>教师详细信息: {selectedTeacher.name}</Title>
      </div>
      
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="教育背景" key="1">
          <div className="tab-content">
            <Table
              columns={educationColumns}
              dataSource={educations}
              rowKey="id"
              loading={educationsLoading}
              pagination={false}
            />
            
            <Divider />
            
            {renderEducationForm()}
          </div>
        </TabPane>
        
        <TabPane tab="语言能力" key="2">
          <div className="tab-content">
            {renderLanguageForm()}
          </div>
        </TabPane>
        
        <TabPane tab="专业证书" key="3">
          <div className="tab-content">
            <Table
              columns={certificateColumns}
              dataSource={certificates}
              rowKey="id"
              loading={certificatesLoading}
              pagination={false}
            />
            
            <Divider />
            
            {renderCertificateForm()}
          </div>
        </TabPane>
        
        <TabPane tab="教学经历" key="4">
          <div className="tab-content">
            <Table
              columns={teachingExpColumns}
              dataSource={teachingExps}
              rowKey="id"
              loading={teachingExpsLoading}
              pagination={false}
            />
            
            <Divider />
            
            {renderTeachingExpForm()}
          </div>
        </TabPane>
        
        <TabPane tab="教学内容" key="5">
          <div className="tab-content">
            <Table
              columns={teachingContentColumns}
              dataSource={teachingContents}
              rowKey="id"
              loading={teachingContentsLoading}
              pagination={false}
            />
            
            <Divider />
            
            {renderTeachingContentForm()}
          </div>
        </TabPane>
        
        <TabPane tab="课时标准" key="6">
          <div className="tab-content">
            <Table
              columns={classHourStandardColumns}
              dataSource={classHourStandards}
              rowKey="id"
              loading={classHourStandardsLoading}
              pagination={false}
            />
            
            <Divider />
            
            {renderClassHourStandardForm()}
          </div>
        </TabPane>
        
        <TabPane tab="教学成果" key="7">
          <div className="tab-content">
            <Table
              columns={teachingResultColumns}
              dataSource={teachingResults}
              rowKey="id"
              loading={teachingResultsLoading}
              pagination={false}
            />
            
            <Divider />
            
            {renderTeachingResultForm()}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TeacherDetailedInfo; 
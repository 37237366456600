import { useState, useCallback } from 'react';
import { message } from 'antd';
import { adminAPI, teacherAPI } from './api';
import { TeacherUser, TeacherBaseInfo } from '../types';

interface UseTeachersReturn {
  teachers: TeacherUser[];
  selectedTeacher: TeacherUser | null;
  loading: boolean;
  error: string | null;
  fetchTeachers: () => Promise<void>;
  fetchTeacherDetails: (id: number) => Promise<TeacherUser | null>;
  createTeacher: (data: { name: string, username: string, password: string }) => Promise<boolean>;
  deleteTeacher: (id: number) => Promise<boolean>;
  updateTeacherBaseInfo: (id: number, data: Partial<TeacherBaseInfo>) => Promise<boolean>;
}

export const useTeachers = (): UseTeachersReturn => {
  const [teachers, setTeachers] = useState<TeacherUser[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<TeacherUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // 通用错误处理函数
  const handleError = useCallback((error: any, operation: string): string => {
    console.error(`${operation}失败:`, error);

    let errorMessage = `${operation}失败: `;
    if (error.response?.data?.message) {
      errorMessage += error.response.data.message;
    } else if (error.response?.data) {
      errorMessage += JSON.stringify(error.response.data);
    } else if (error.message) {
      errorMessage += error.message;
    } else {
      errorMessage += '服务器错误';
    }

    setError(errorMessage);
    message.error(errorMessage);
    return errorMessage;
  }, []);

  // 获取所有教师列表
  const fetchTeachers = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('获取教师列表...');

      const response = await adminAPI.getAllTeachers();
      console.log('获取教师列表响应:', response.status);

      if (response.data) {
        console.log('教师列表数据:', response.data);
        setTeachers(response.data);
        return;
      }

      throw new Error('教师列表数据格式不正确');
    } catch (error: any) {
      handleError(error, '获取教师列表');
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  // 获取教师详情
  const fetchTeacherDetails = useCallback(async (id: number) => {
    try {
      setLoading(true);
      setError(null);
      console.log(`获取教师详情，ID: ${id}`);

      const response = await adminAPI.getTeacher(id);
      console.log('获取教师详情响应:', response.status);

      if (response.data) {
        console.log('教师用户数据:', response.data);
        
        // 获取教师基本信息
        try {
          console.log('获取教师基本信息...');
          const baseInfoResponse = await teacherAPI.getBaseInfo(id);
          
          if (baseInfoResponse.data) {
            console.log('教师基本信息数据:', baseInfoResponse.data);
            
            // 合并教师用户数据和基本信息
            const teacherWithBaseInfo = {
              ...response.data,
              baseInfo: baseInfoResponse.data
            };
            
            console.log('合并后的完整教师数据:', teacherWithBaseInfo);
            
            // 更新全局状态
            setSelectedTeacher(teacherWithBaseInfo);
            return teacherWithBaseInfo;
          }
        } catch (baseInfoError) {
          console.error('获取教师基本信息失败:', baseInfoError);
          // 即使基本信息获取失败，仍然返回教师用户数据
          setSelectedTeacher(response.data);
          return response.data;
        }
        
        // 如果没有获取到基本信息，仍然返回教师用户数据
        setSelectedTeacher(response.data);
        return response.data;
      }

      throw new Error('获取教师详情失败');
    } catch (error: any) {
      handleError(error, '获取教师详情');
      return null;
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  // 创建教师
  const createTeacher = useCallback(async (data: { name: string, username: string, password: string }) => {
    try {
      setLoading(true);
      console.log('创建教师用户:', data);

      const response = await adminAPI.createTeacher(data);
      console.log('创建教师响应:', response.status);

      message.success('教师创建成功');
      fetchTeachers(); // 刷新教师列表
      return true;
    } catch (error: any) {
      handleError(error, '创建教师');
      return false;
    } finally {
      setLoading(false);
    }
  }, [fetchTeachers, handleError]);

  // 删除教师
  const deleteTeacher = useCallback(async (id: number) => {
    try {
      setLoading(true);
      console.log(`开始删除教师，ID: ${id}`);

      // 显示确认中消息
      const hide = message.loading('正在删除教师...', 0);
      
      await adminAPI.deleteTeacher(id);
      
      // 隐藏加载消息
      hide();
      
      message.success('教师删除成功');
      fetchTeachers(); // 刷新教师列表
      return true;
    } catch (error: any) {
      console.error('删除教师失败:', error);
      
      // 提取更详细的错误信息
      let errorDetails = '';
      if (error.serverMessage) {
        errorDetails = error.serverMessage;
      } else if (error.response?.data?.message) {
        errorDetails = error.response.data.message;
      } else if (error.message) {
        errorDetails = error.message;
      }
      
      // 使用更详细的错误信息
      message.error(`删除教师失败: ${errorDetails || '服务器错误'}`);
      setError(`删除教师失败: ${errorDetails || '服务器错误'}`);
      return false;
    } finally {
      setLoading(false);
    }
  }, [fetchTeachers, setError]);

  // 更新教师基本信息
  const updateTeacherBaseInfo = useCallback(async (id: number, data: Partial<TeacherBaseInfo>) => {
    try {
      setLoading(true);
      console.log(`更新教师基本信息，ID: ${id}`, data);

      // 发送请求前先完整备份当前教师信息，以防出错后可以恢复
      const originalTeacher = selectedTeacher ? { ...selectedTeacher } : null;

      // 发送更新请求
      const response = await teacherAPI.updateBaseInfo(id, data);
      console.log('更新教师基本信息响应:', response.status, response.data);

      // 服务器返回了更新后的完整教师信息
      if (response.data) {
        // 直接使用服务器返回的完整数据更新本地状态
        setSelectedTeacher(response.data);
        console.log('本地教师信息已更新为服务器返回的最新数据');
      } 
      // 如果服务器没有返回完整数据，则手动更新本地缓存
      else if (selectedTeacher && selectedTeacher.id === id) {
        const baseInfo = selectedTeacher.baseInfo || {};
        
        // 合并新的基本信息数据
        const updatedBaseInfo = {
          ...baseInfo,
          ...data,
          // 确保日期正确格式化
          birthDate: data.birthDate || baseInfo.birthDate,
          // 添加更新时间
          updatedAt: new Date().toISOString().split('T')[0]
        };
        
        const updatedTeacher = { 
          ...selectedTeacher, 
          baseInfo: updatedBaseInfo,
          hasBaseInfo: true // 确保标记为已有基本信息
        };
        
        // 更新本地缓存的教师信息
        setSelectedTeacher(updatedTeacher);
        console.log('本地教师信息已手动更新:', updatedTeacher);
      }

      return true;
    } catch (error: any) {
      handleError(error, '更新教师基本信息');
      return false;
    } finally {
      setLoading(false);
    }
  }, [selectedTeacher, setSelectedTeacher, handleError]);

  return {
    teachers,
    selectedTeacher,
    loading,
    error,
    fetchTeachers,
    fetchTeacherDetails,
    createTeacher,
    deleteTeacher,
    updateTeacherBaseInfo
  };
};

export default useTeachers; 
import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, Space, Tabs, Divider } from 'antd';
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../services/useAuth';
import './Login.css';

const { Title } = Typography;
const { TabPane } = Tabs;

interface LoginProps {
  redirect?: string;
}

const Login: React.FC<LoginProps> = ({ redirect = '/admin/dashboard' }) => {
  const { login, loading } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("login");

  const handleSubmit = async (values: { username: string; password: string }) => {
    setLoginError(null);
    
    try {
      const success = await login(values.username, values.password);
      
      if (success) {
        navigate(redirect);
      } else {
        setLoginError('登录失败，请检查用户名和密码');
      }
    } catch (error) {
      console.error('登录过程出错:', error);
      setLoginError('登录过程中发生错误，请稍后再试');
    }
  };

  const goToHomePage = () => {
    navigate('/');
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <div className="login-header">
          <Title level={4} className="login-title">Teacher私下聊</Title>
        </div>
        
        <Tabs 
          activeKey={activeTab} 
          onChange={setActiveTab}
          className="login-tabs"
          centered
        >
          <TabPane tab="登录" key="login">
            <Form
              form={form}
              name="login"
              onFinish={handleSubmit}
              autoComplete="off"
              layout="vertical"
              className="login-form"
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入用户名/手机号' }]}
              >
                <Input 
                  prefix={<UserOutlined className="input-icon" />} 
                  placeholder="用户名/手机号" 
                  size="large"
                  className="login-input"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password 
                  prefix={<LockOutlined className="input-icon" />} 
                  placeholder="密码" 
                  size="large"
                  className="login-input"
                  iconRender={visible => (
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  )}
                />
              </Form.Item>

              {loginError && (
                <div className="login-error">
                  {loginError}
                </div>
              )}

              <Form.Item className="login-buttons">
                <Button
                  onClick={goToHomePage}
                  className="back-button"
                >
                  返回
                </Button>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading}
                  className="submit-button"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="注册" key="register" disabled>
            {/* 注册功能可以在未来实现 */}
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Login; 
import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import { adminAPI } from './api';

// Token过期时间（毫秒）- 设置为24小时
const TOKEN_EXPIRY_TIME = 24 * 60 * 60 * 1000;

// 使用sessionStorage而不是localStorage - 这样浏览器关闭后会自动清除，但单页面应用中不会因刷新丢失
const getItem = (key: string) => sessionStorage.getItem(key);
const setItem = (key: string, value: string) => sessionStorage.setItem(key, value);
const removeItem = (key: string) => sessionStorage.removeItem(key);

// 检查token是否过期（简化逻辑，减少出错概率）
const isTokenExpired = (): boolean => {
  try {
    // 如果会话中存在令牌，则视为有效 - 使用sessionStorage简化管理，避免过期检查的复杂性
    const token = getItem('token');
    return !token;
  } catch (e) {
    console.error('检查令牌时发生错误:', e);
    return true;
  }
};

// 清除所有认证信息
const clearAuthData = () => {
  removeItem('token');
  removeItem('userType');
  removeItem('userId');
  removeItem('username');
};

interface UseAuthReturn {
  isLoggedIn: boolean;
  userType: string | null;
  isAdmin: boolean;
  userId: number | null;
  username: string | null;
  loading: boolean;
  error: string | null;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
}

// 从sessionStorage获取用户信息并更新状态
const updateStateFromStorage = (
  setIsLoggedIn: (value: boolean) => void,
  setUserType: (value: string | null) => void,
  setIsAdmin: (value: boolean) => void,
  setUserId: (value: number | null) => void,
  setUsername: (value: string | null) => void
) => {
  const token = getItem('token');
  const storedUserType = getItem('userType');
  const storedUserId = getItem('userId');
  const storedUsername = getItem('username');
  
  if (token && storedUserType) {
    setIsLoggedIn(true);
    setUserType(storedUserType);
    
    if (storedUserId) {
      setUserId(parseInt(storedUserId, 10));
    }
    
    if (storedUsername) {
      setUsername(storedUsername);
    }
    
    if (storedUserType === 'ADMIN') {
      setIsAdmin(true);
    }
  } else {
    setIsLoggedIn(false);
    setUserType(null);
    setIsAdmin(false);
    setUserId(null);
    setUsername(null);
  }
};

export const useAuth = (): UseAuthReturn => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userType, setUserType] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // 通用错误处理函数
  const handleError = useCallback((error: any, operation: string): string => {
    console.error(`${operation}失败:`, error);

    let errorMessage = `${operation}失败: `;
    if (error.response?.data?.message) {
      errorMessage += error.response.data.message;
    } else if (error.response?.data) {
      errorMessage += JSON.stringify(error.response.data);
    } else if (error.message) {
      errorMessage += error.message;
    } else {
      errorMessage += '服务器错误';
    }

    setError(errorMessage);
    message.error(errorMessage);
    return errorMessage;
  }, []);

  // 初始化和路由变化时检查认证状态
  useEffect(() => {
    console.log('初始化认证状态...');
    updateStateFromStorage(setIsLoggedIn, setUserType, setIsAdmin, setUserId, setUsername);
    
    // 添加事件监听器，以便在存储变化时更新状态
    const handleStorageChange = () => {
      console.log('存储变化，更新认证状态...');
      updateStateFromStorage(setIsLoggedIn, setUserType, setIsAdmin, setUserId, setUsername);
    };
    
    // 监听存储变化事件
    window.addEventListener('storage', handleStorageChange);
    
    // 清除事件监听器
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // 登录
  const login = useCallback(async (username: string, password: string): Promise<boolean> => {
    try {
      setLoading(true);
      setError(null);
      console.log('开始登录过程...');

      const response = await adminAPI.login(username, password);
      console.log('登录响应状态:', response.status);
      
      if (response.data && response.data.token) {
        console.log('登录成功，收到令牌');
        
        // 使用sessionStorage保存令牌和用户信息
        setItem('token', response.data.token);
        setItem('userType', response.data.userType);
        setItem('userId', response.data.userId.toString());
        setItem('username', response.data.username);
        
        // 更新状态
        updateStateFromStorage(setIsLoggedIn, setUserType, setIsAdmin, setUserId, setUsername);
        
        console.log('认证状态已更新，用户现在已登录');
        message.success('登录成功');
        return true;
      } else {
        throw new Error('登录响应格式不正确');
      }
    } catch (error: any) {
      handleError(error, '登录');
      return false;
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  // 登出
  const logout = useCallback(() => {
    // 清除sessionStorage中的认证信息
    clearAuthData();
    
    // 更新状态
    setIsLoggedIn(false);
    setUserType(null);
    setIsAdmin(false);
    setUserId(null);
    setUsername(null);
    
    message.success('退出登录成功');
  }, []);

  return {
    isLoggedIn,
    userType,
    isAdmin,
    userId,
    username,
    loading,
    error,
    login,
    logout
  };
};

export default useAuth; 
import axios, { AxiosProgressEvent } from 'axios';

// 错误消息常量
const ERROR_MESSAGES = {
  UNAUTHORIZED: '认证失败，请重新登录',
  FORBIDDEN: '您没有权限执行此操作，请联系管理员',
  SERVER_ERROR: '服务器内部错误，请稍后再试或联系技术支持',
  NETWORK_ERROR: '网络连接失败，无法连接到服务器',
  REQUEST_ERROR: '请求错误',
  NO_TOKEN: '未登录，请先登录',
  TOKEN_EXPIRED: '登录已过期，请重新登录',
  BAD_REQUEST: '请求参数错误，请检查输入',
  NOT_FOUND: '请求的资源不存在',
  TIMEOUT: '请求超时，请检查网络连接',
  CONFLICT: '资源冲突，可能已存在相同记录'
};

// Token过期时间（毫秒）- 设置为24小时
const TOKEN_EXPIRY_TIME = 24 * 60 * 60 * 1000;

// 使用sessionStorage而不是localStorage
const getItem = (key: string) => sessionStorage.getItem(key);
const setItem = (key: string, value: string) => sessionStorage.setItem(key, value);
const removeItem = (key: string) => sessionStorage.removeItem(key);

// 检查token是否过期
const isTokenExpired = (): boolean => {
  const tokenTimestamp = getItem('tokenTimestamp');
  console.log('API拦截器 - 检查令牌是否过期，时间戳:', tokenTimestamp);
  
  if (!tokenTimestamp) {
    console.warn('API拦截器 - 未找到令牌时间戳，视为已过期');
    return true;
  }
  
  try {
    const timestamp = parseInt(tokenTimestamp, 10);
    const now = new Date().getTime();
    const diff = now - timestamp;
    const isExpired = diff > TOKEN_EXPIRY_TIME;
    
    console.log('API拦截器 - 令牌年龄:', diff / 1000, '秒,', 'TOKEN_EXPIRY_TIME:', TOKEN_EXPIRY_TIME / 1000, '秒, 是否过期:', isExpired);
    
    return isExpired;
  } catch (e) {
    console.error('API拦截器 - 解析令牌时间戳发生错误:', e);
    return true;
  }
};

// 创建axios实例
const api = axios.create({
  baseURL: '/api',
  timeout: 60000 // 将默认超时时间从15秒增加到60秒
});

// 请求拦截器：添加token，但不处理重定向
api.interceptors.request.use(
  (config) => {
    const token = getItem('token');
    console.log('请求拦截器：URL', config.url, '，方法', config.method);
    
    // 添加令牌到请求头
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('已添加认证令牌到请求头');
    } else {
      console.log('未找到认证令牌，请求将以匿名方式发送');
    }
    
    return config;
  },
  (error) => {
    console.error('请求拦截器错误:', error);
    return Promise.reject(error);
  }
);

// 清除所有认证信息
const clearAuthData = () => {
  removeItem('token');
  removeItem('userType');
  removeItem('userId');
  removeItem('username');
};

// 响应拦截器：处理错误
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // 构建详细的错误日志对象
    const errorDetails = {
      url: error.config?.url || 'unknown',
      method: error.config?.method || 'unknown',
      status: error.response?.status || 'no response',
      statusText: error.response?.statusText || '',
      data: error.response?.data || {},
      message: error.message || 'Unknown error',
      timestamp: new Date().toISOString()
    };
    
    // 尝试提取最详细的错误信息
    let detailedErrorMessage = '';
    
    if (error.response) {
      // 服务器返回了错误响应
      console.error('API响应错误:', errorDetails);
      
      // 提取服务器返回的错误信息
      if (error.response.data) {
        if (typeof error.response.data === 'object') {
          if (error.response.data.message) {
            detailedErrorMessage = error.response.data.message;
          } else if (error.response.data.error) {
            detailedErrorMessage = error.response.data.error;
          } else {
            detailedErrorMessage = JSON.stringify(error.response.data);
          }
        } else if (typeof error.response.data === 'string') {
          detailedErrorMessage = error.response.data;
        }
      }
      
      // 附加到错误对象中，以便调用函数可以访问
      error.serverMessage = detailedErrorMessage || error.response.statusText;
      
      switch (error.response.status) {
        case 400:
          console.warn('请求参数错误:', errorDetails.data);
          break;
          
        case 401:
          // 未授权，清除token并提示用户
          clearAuthData();
          console.warn('会话已过期，请重新登录');
          break;
          
        case 403:
          console.warn('权限不足，无法访问资源:', errorDetails.url);
          break;
          
        case 404:
          console.warn('请求的资源不存在:', errorDetails.url);
          break;
          
        case 409:
          console.warn('资源冲突，可能已存在相同记录');
          break;
          
        case 500:
          console.error('服务器内部错误:', errorDetails);
          break;
          
        default:
          console.error('未处理的HTTP错误状态码:', errorDetails);
      }
    } else if (error.request) {
      // 请求已发送但未收到响应
      console.error('未收到API响应:', {
        ...errorDetails,
        request: error.request
      });
      
      if (error.code === 'ECONNABORTED') {
        console.error('请求超时:', errorDetails.url);
      } else {
        // 网络连接问题
        console.error(ERROR_MESSAGES.NETWORK_ERROR);
      }
    } else {
      // 请求配置时出错
      console.error('API请求配置错误:', errorDetails);
    }
    
    return Promise.reject(error);
  }
);

// 保存token和用户信息
const saveToken = (token: string, userType: string, userId: number, username: string) => {
  try {
    console.log('保存认证令牌和用户信息...');
    
    if (!token || token.trim() === '') {
      console.error('尝试保存无效令牌!');
      return;
    }
    
    // 首先清除任何现有的令牌数据
    clearAuthData();
    
    // 使用sessionStorage保存令牌和用户信息 - 更简单且更可靠
    setItem('token', token);
    setItem('userType', userType);
    setItem('userId', userId.toString());
    setItem('username', username);
    
    console.log('令牌和用户信息已保存到会话存储');
  } catch (error) {
    console.error('保存令牌时出错:', error);
  }
};

// Admin API
export const adminAPI = {
  // 管理员登录
  login: (username: string, password: string) => {
    console.log('发送登录请求...');
    return api.post('/admin/login', { username, password })
      .then(response => {
        console.log('收到登录响应，状态:', response.status);
        if (response.data && response.data.token) {
          // 保存token和用户信息
          saveToken(
            response.data.token,
            response.data.userType,
            response.data.userId,
            response.data.username
          );
        } else {
          console.error('登录响应中没有令牌!', response.data);
        }
        return response;
      });
  },
  
  // 创建教师用户
  createTeacher: (data: { name: string, username: string, password: string }) => {
    return api.post('/admin/teachers', data);
  },
  
  // 获取所有教师用户
  getAllTeachers: () => {
    return api.get('/admin/teachers');
  },
  
  // 获取教师用户详情
  getTeacher: (id: number) => {
    return api.get(`/admin/teachers/${id}`);
  },
  
  // 删除教师用户
  deleteTeacher: (id: number) => {
    return api.delete(`/admin/teachers/${id}`);
  }
};

// Teacher API
export const teacherAPI = {
  // 获取教师基本信息
  getBaseInfo: (id: number, config = {}) => {
    return api.get(`/teachers/${id}/baseinfo`, config);
  },
  
  // 更新教师基本信息
  updateBaseInfo: (id: number, data: any, config = {}) => {
    return api.put(`/teachers/${id}/baseinfo`, data, config);
  },
  
  // 教育背景相关
  addEducationInfo: (teacherId: number, data: any, config = {}) => {
    return api.post(`/teachers/${teacherId}/educations`, data, config);
  },
  
  getEducationInfoList: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/educations`, config);
  },
  
  updateEducationInfo: (educationId: number, data: any, config = {}) => {
    return api.put(`/teachers/educations/${educationId}`, data, config);
  },
  
  deleteEducationInfo: (educationId: number, config = {}) => {
    return api.delete(`/teachers/educations/${educationId}`, config);
  },
  
  // 语言能力相关
  updateLanguageAbilityInfo: (teacherId: number, data: any, config = {}) => {
    return api.put(`/teachers/${teacherId}/language`, data, config);
  },
  
  getLanguageAbilityInfo: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/language`, config);
  },
  
  // 证书相关
  addCertificateInfo: (teacherId: number, data: any, config = {}) => {
    return api.post(`/teachers/${teacherId}/certificates`, data, config);
  },
  
  getCertificateInfoList: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/certificates`, config);
  },
  
  updateCertificateInfo: (certificateId: number, data: any, config = {}) => {
    return api.put(`/teachers/certificates/${certificateId}`, data, config);
  },
  
  deleteCertificateInfo: (certificateId: number, config = {}) => {
    return api.delete(`/teachers/certificates/${certificateId}`, config);
  },
  
  // 教学经历相关
  addTeachingExpInfo: (teacherId: number, data: any, config = {}) => {
    return api.post(`/teachers/${teacherId}/experiences`, data, config);
  },
  
  getTeachingExpInfoList: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/experiences`, config);
  },
  
  updateTeachingExpInfo: (expId: number, data: any, config = {}) => {
    return api.put(`/teachers/experiences/${expId}`, data, config);
  },
  
  deleteTeachingExpInfo: (expId: number, config = {}) => {
    return api.delete(`/teachers/experiences/${expId}`, config);
  },
  
  // 教学内容相关
  addTeachingContentInfo: (teacherId: number, data: any, config = {}) => {
    return api.post(`/teachers/${teacherId}/contents`, data, config);
  },
  
  getTeachingContentInfoList: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/contents`, config);
  },
  
  updateTeachingContentInfo: (contentId: number, data: any, config = {}) => {
    return api.put(`/teachers/contents/${contentId}`, data, config);
  },
  
  deleteTeachingContentInfo: (contentId: number, config = {}) => {
    return api.delete(`/teachers/contents/${contentId}`, config);
  },
  
  // 课时标准相关
  addClassHourStandardInfo: (teacherId: number, data: any, config = {}) => {
    return api.post(`/teachers/${teacherId}/standards`, data, config);
  },
  
  getClassHourStandardInfoList: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/standards`, config);
  },
  
  updateClassHourStandardInfo: (standardId: number, data: any, config = {}) => {
    return api.put(`/teachers/standards/${standardId}`, data, config);
  },
  
  deleteClassHourStandardInfo: (standardId: number, config = {}) => {
    return api.delete(`/teachers/standards/${standardId}`, config);
  },
  
  // 教学成果相关
  addTeachingResultInfo: (teacherId: number, data: any, config = {}) => {
    return api.post(`/teachers/${teacherId}/results`, data, config);
  },
  
  getTeachingResultInfoList: (teacherId: number, config = {}) => {
    return api.get(`/teachers/${teacherId}/results`, config);
  },
  
  updateTeachingResultInfo: (resultId: number, data: any, config = {}) => {
    return api.put(`/teachers/results/${resultId}`, data, config);
  },
  
  deleteTeachingResultInfo: (resultId: number, config = {}) => {
    return api.delete(`/teachers/results/${resultId}`, config);
  }
};

// File API
export const fileAPI = {
  uploadFile: (file: File, onProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    const formData = new FormData();
    formData.append('file', file);
    
    return api.post('/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onProgress
    });
  },
  
  uploadVideo: (file: File, onProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    const formData = new FormData();
    formData.append('file', file);
    
    return api.post('/files/upload-video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 300000, // 增加视频上传的超时时间到5分钟
      onUploadProgress: onProgress
    });
  }
};

export default api; 
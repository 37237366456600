import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { message, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/reset.css';
import './styles.css';

// 导入组件
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import TeacherBasicInfo from './components/TeacherBasicInfo';
import TeacherDetailedInfo from './components/TeacherDetailedInfo';
import HomePage from './components/HomePage';
import { useAuth } from './services/useAuth';

// 受保护的路由 - 更加强健的版本
const ProtectedRoute: React.FC<{
  element: React.ReactNode;
  redirectPath?: string;
}> = ({ element, redirectPath = '/login' }) => {
  const { isLoggedIn, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  
  // 仅在组件挂载时检查一次登录状态
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialized(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);
  
  // 仅在初始化后且用户未登录时才重定向
  useEffect(() => {
    if (!initialized) return;
    
    if (!isLoggedIn) {
      console.log('ProtectedRoute: 用户未登录，重定向到登录页面');
      navigate(redirectPath, { replace: true });
    } else if (!isAdmin) {
      console.log('ProtectedRoute: 用户不是管理员，重定向到登录页面');
      navigate('/login', { replace: true });
    }
  }, [initialized, isLoggedIn, isAdmin, navigate, redirectPath]);
  
  // 跳过加载状态，直接渲染内容
  // 这样可以避免在子路由之间切换时闪烁
  return <>{element}</>;
};

// 应用
const App: React.FC = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <Routes>
          {/* 公开路由 - 首页和登录页面 */}
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          
          {/* 管理员仪表板路由 - 需要登录 */}
          <Route 
            path="/admin/dashboard" 
            element={
              <ProtectedRoute element={<AdminDashboard />} />
            }
          />
          
          {/* 教师基本信息路由 - 需要登录 */}
          <Route 
            path="/admin/teachers/:id/basic-info" 
            element={
              <ProtectedRoute element={<TeacherBasicInfo />} />
            }
          />
          
          {/* 教师详细信息路由 - 需要登录 */}
          <Route 
            path="/admin/teachers/:id/detailed-info" 
            element={
              <ProtectedRoute element={<TeacherDetailedInfo />} />
            }
          />
          
          {/* 其他路径重定向到首页 */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App; 